<template>
    <div>
        <v-container fluid class="mb-4" :dir="lang() === 'en' ? 'ltr' : 'rtl'">
            <v-row class="text-center">
                <v-col cols="12 text-start mx-auto notprintable" sm="12">
                    <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                    </v-btn>
                    <v-btn @click="refreshAll()" :disabled="!disableinvoice || disableall"
                        :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 radius-5 nzar-font white--text info" large>
                        <v-icon> mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn @click="printInvoiceConfirmation()" :disabled="!disableinvoice || disableall"
                        v-if="fastsalelist.length > 0" :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 radius-5 nzar-font white--text primary d-none" large>
                        <v-icon> mdi-printer</v-icon>
                    </v-btn>
                    <v-btn @click="saveInvoiceConfirmation()" :disabled="!disableinvoice || disableall"
                        v-if="fastsalelist.length > 0" :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 radius-5 nzar-font white--text success" large>
                        <v-icon> mdi-invoice</v-icon>
                    </v-btn>
                    <v-btn @click="returnInvoiceConfirmation()" :disabled="!disableinvoice || disableall"
                        v-if="fastsalelist.length > 0" :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 radius-5 nzar-font white--text warning d-none" large>
                        <v-icon> mdi-reload</v-icon>
                    </v-btn>
                    <v-btn @click="emptyAllConfirmation()" :disabled="!disableinvoice || disableall"
                        v-if="fastsalelist.length > 0" :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 radius-5 nzar-font white--text red" large>
                        <v-icon> mdi-cup-outline</v-icon>
                    </v-btn>
                </v-col>

            </v-row>

            <v-row class="alert mt-4">
                <v-col class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6 mx-auto text-center" v-if="error_msg != null">
                    <v-alert dense type="warning">{{ error_msg }}</v-alert>
                </v-col>
                <v-col class="col-11 col-sm-11 col-md-6 col-lg-3 col-xl-2 mx-auto text-center"
                    v-if="success_msg != null">
                    <v-alert dense type="success">{{ success_msg }}</v-alert>
                </v-col>
            </v-row>


            <v-row class="text-center">
                <v-col cols="12" class="col-11 col-sm-11 col-md-5 col-lg-5 col-xl-5 mx-auto text-center notprintable">
                    <div class="pa-3 bg-mode overflow-y-auto"
                        style="border:1px solid black;border-radius:1rem;height:800px;">
                        <div class="">
                            <v-text-field autofocus clearable filled class="nzar-font ma-auto col-11" rounded outlined
                                append-icon="mdi-magnify" @keyup="searchProduct()" v-model="search"
                                :label="langkeyword('SEARCH')">
                            </v-text-field>
                            <v-item-group>
                                <v-container>
                                    <v-row>
                                        <v-col v-for="(item, index) in products" :key="index" cols="6" md="6" lg="4"
                                            xl="2">
                                            <v-item>
                                                <v-card :disabled="!disableinvoice" style="cursor: pointer;"
                                                    v-on:click="addProduct(item.id)" elevation="3" height="230">
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="mb-1 text-wrap"
                                                                style="font-size:0.9rem">
                                                                <span v-if="lang() === 'ku'">{{
                                                                    item.ku_name }}</span>
                                                                <span v-if="lang() === 'ar'">{{
                                                                    item.ar_name }}</span>
                                                                <span v-if="lang() === 'en'">{{
                                                                    item.en_name }}</span>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-row>
                                                        <v-card-actions class="col-10 text-center ma-auto">
                                                            <v-img style="height:60px;object-fit:contain;"
                                                                class="mx-auto rounded-lg" contain
                                                                :src="uploadPath(item.avatar)"></v-img>
                                                        </v-card-actions>
                                                    </v-row>
                                                    <v-chip color="success" class="pa-1">
                                                        {{ item.qty }}
                                                    </v-chip>
                                                </v-card>
                                            </v-item>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-item-group>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 mx-auto text-center pa-1">


                    <v-form class="row notprintable">
                        <v-text-field type="number" min="0" step="any" :disabled="disableinvoice" outlined dense
                            class="nzar-font col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 ma-1" v-model="invoicenumber"
                            :label="langkeyword('INVOICENUMBER')">
                        </v-text-field>
                        <v-text-field type="date" :disabled="disableinvoice" outlined dense
                            class="nzar-font col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 ma-1" v-model="date"
                            :label="langkeyword('DATE')">
                        </v-text-field>
                        <v-text-field :disabled="disableinvoice" outlined dense
                            class="nzar-font col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 ma-1"
                            append-icon="mdi-format-title" v-model="note" :label="langkeyword('NOTE')">
                        </v-text-field>
                        <v-autocomplete v-if="setting().hidedollarsale == 'no' && setting().hidedinarsale == 'no'"
                            v-model="currency_id" :items="currencies" :item-text="langoptions('value') + '_name'"
                            item-value="id" :label="langkeyword('CURRENCY')" :disabled="disableinvoice" outlined
                            class="nzar-font col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 ma-1" dense></v-autocomplete>
                        <v-autocomplete v-model="customer_id" :items="customers" item-text="name" item-value="id"
                            :label="langkeyword('CUSTOMER')" :disabled="disableinvoice" outlined
                            class="nzar-font col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 ma-1" dense></v-autocomplete>
                        <v-autocomplete v-if="setting().hideinvoicetypesale == 'no'" v-model="invoicetype_id"
                            :items="invoicetypes" :item-text="langoptions('value') + '_name'" item-value="id"
                            :label="langkeyword('INVOICETYPE')" :disabled="disableinvoice" outlined
                            class="nzar-font col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 ma-1" dense></v-autocomplete>
                        <v-autocomplete v-model="store_id" :items="stores" v-if="!isNormal()"
                            :item-text="langoptions('value') + '_name'" item-value="id" :label="langkeyword('STORES')"
                            :disabled="disableinvoice" outlined
                            class="nzar-font col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 ma-1" dense></v-autocomplete>
                        <v-autocomplete v-model="treasure_id" :items="treasures"
                            :item-text="langoptions('value') + '_name'" item-value="id" :label="langkeyword('TREASURE')"
                            :disabled="disableinvoice" outlined
                            class="nzar-font col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 ma-1" dense></v-autocomplete>
                        <v-btn color="success" class="ma-1 pa-2" v-if="!disableinvoice" @click="saveMainInvoice()">
                            <v-icon>mdi-pen</v-icon>
                            {{ langkeyword('SAVE') }}
                        </v-btn>
                        <v-btn color="warning" class="ma-1 pa-2" v-if="disableinvoice" @click="updateMainInvoice()">
                            <v-icon>mdi-pencil</v-icon>
                            {{ langkeyword('UPDATE') }}
                        </v-btn>
                    </v-form>




                    <v-simple-table class="printtable">
                        <thead>
                            <tr>
                                <th class="text-center">
                                    {{ langkeyword('NAME') }}
                                </th>
                                <th class="text-center">
                                    {{ langkeyword('BARCODE') }}
                                </th>
                                <th class="text-center">
                                    {{ langkeyword('QUANTITY') }}
                                </th>
                                <th class="text-center">
                                    {{ langkeyword('PRICE') }}
                                </th>
                                <th class="text-center">
                                    {{ langkeyword('TOTAL') }}
                                </th>
                                <th class="notprintable text-center">
                                    {{ langkeyword('ACTIONS') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="(product, index) in fastsalelist" :key="index">
                            <tr>
                                <td class="text-wrap">
                                    <span v-if="lang() === 'ku'" class="text-wrap">{{ product.ku_name }}</span>
                                    <span v-if="lang() === 'ar'" class="text-wrap">{{ product.ar_name }}</span>
                                    <span v-if="lang() === 'en'" class="text-wrap">{{ product.en_name }}</span>
                                </td>
                                <td class="text-wrap">
                                    {{ product.barcode }}
                                </td>
                                <td class="d-print-none">
                                    <center>
                                        <v-text-field
                                            :rules="[v => v <= product.qty || langkeyword('NOT_VALID_AMOUNT')]"
                                            type="number" min="0" step="any" hide-spin-buttons outlined dense filled
                                            hide-details :disabled="!disableinvoice || disableall"
                                            :value="product.fastsaleqty" class="nzar-font text-center"
                                            @input="changeFspQty(product, $event.target)"
                                            @dblclick="changeFspQty(product, $event.target)"
                                            @keyup="changeFspQty(product, $event.target)"
                                            @change="changeFspQty(product, $event.target)"
                                            @keypress="changeFspQty(product, $event.target)"
                                            @keydown="changeFspQty(product, $event.target)"
                                            @click="changeFspQty(product, $event.target)"
                                            @mouseleave="changeFspQty(product, $event.target)"
                                            @mouseenter="changeFspQty(product, $event.target)"> </v-text-field>
                                    </center>
                                </td>
                                <td class="d-none d-print-table-cell">
                                    {{ product.fastsaleqty }}
                                </td>
                                <td class="d-print-none">
                                    <center>
                                        <v-text-field type="number" min="0" step="any" hide-spin-buttons outlined dense
                                            filled hide-details :disabled="!disableinvoice || disableall"
                                            :value="currency_id == 1 ? product.sale_price_iqd : product.sale_price_usd"
                                            class="nzar-font text-center"
                                            @input="changeFspSalePrice(product, $event.target)"
                                            @dblclick="changeFspSalePrice(product, $event.target)"
                                            @keyup="changeFspSalePrice(product, $event.target)"
                                            @change="changeFspSalePrice(product, $event.target)"
                                            @keypress="changeFspSalePrice(product, $event.target)"
                                            @keydown="changeFspSalePrice(product, $event.target)"
                                            @click="changeFspSalePrice(product, $event.target)"
                                            @mouseleave="changeFspSalePrice(product, $event.target)"
                                            @mouseenter="changeFspSalePrice(product, $event.target)">
                                        </v-text-field>
                                    </center>
                                </td>
                                <td class="d-none d-print-table-cell" v-if="currency_id == 1">{{
                                    dinar(product.sale_price_iqd) }}
                                </td>
                                <td class="d-none d-print-table-cell" v-if="currency_id == 2">{{
                                    dollar(product.sale_price_usd) }}
                                </td>
                                <td v-if="currency_id == 1">{{ dinar(parseFloat(product.fastsaleqty) *
                                    product.sale_price_iqd) }}
                                </td>
                                <td v-if="currency_id == 2">{{ dollar(parseFloat(product.fastsaleqty) *
                                    product.sale_price_usd) }}
                                </td>
                                <td class="notprintable">
                                    <v-btn color="red" class="ma-1 white--text" x-small :disabled="!disableinvoice"
                                        @click="deleteProduct(product.id)"><v-icon>mdi-delete</v-icon></v-btn>
                                    <v-btn color="success" class="d-none ma-1 white--text" x-small
                                        :disabled="!disableinvoice"
                                        @click="plusQty(product.id)"><v-icon>mdi-plus</v-icon></v-btn>
                                    <v-btn color="warning" class="d-none ma-1 white--text" x-small
                                        :disabled="!disableinvoice"
                                        @click="minusQty(product.id)"><v-icon>mdi-minus</v-icon></v-btn>
                                    <v-btn color="info" class="ma-1 white--text" x-small :disabled="!disableinvoice"
                                        @click="refreshProduct(product.id, index)"><v-icon>mdi-refresh</v-icon></v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-simple-table class="pa-3 text-center">
                        <thead>
                            <tr v-if="setting().hidediscountsale == 'no'">
                                <th class="text-center ma-auto">
                                    <h2>{{ langkeyword('DISCOUNT') }}:</h2>
                                </th>
                                <th>
                                    <center>
                                        <v-text-field
                                            :rules="[v => (v <= totalProfit()) || this.langkeyword('NOT_VALID_AMOUNT')]"
                                            type="number" min="0" step="any" outlined dense
                                            :disabled="!disableinvoice || disableall"
                                            class="nzar-font col-10 col-sm-6 col-md-4 col-lg-3 col-xl-3 ma-1 center--text"
                                            v-model="discount" :label="langkeyword('DISCOUNT')"
                                            @input="changeDiscount($event.target)"
                                            @dblclick="changeDiscount($event.target)" @click="$emit('someEvent')"
                                            @keyup="changeDiscount($event.target)"
                                            @change="changeDiscount($event.target)"
                                            @keypress="changeDiscount($event.target)"
                                            @keydown="changeDiscount($event.target)">
                                        </v-text-field>
                                    </center>
                                </th>
                            </tr>
                            <tr
                                v-if="($store.state.dashboardauth.role_id == 1 || $store.state.dashboardauth.role_id == 2) && setting().hideprofitsale == 'no'">
                                <th class="text-center ma-auto">
                                    <h2>{{ langkeyword('PROFIT') }}:</h2>
                                </th>
                                <th class="text-center ma-auto">
                                    <h2 v-if="currency_id == 1">{{ dinar(totalProfit()) }}</h2>
                                    <h2 v-if="currency_id == 2">{{ dollar(totalProfit()) }}</h2>
                                </th>
                            </tr>
                            <tr>
                                <th class="text-center ma-auto">
                                    <h2>{{ langkeyword('ALLTOTAL') }}:</h2>
                                </th>
                                <th class="text-center ma-auto">
                                    <h2 v-if="currency_id == 1">{{ dinar(totalInvoice()) }}</h2>
                                    <h2 v-if="currency_id == 2">{{ dollar(totalInvoice()) }}</h2>
                                </th>
                            </tr>
                        </thead>
                    </v-simple-table>
                </v-col>



            </v-row>
            <v-row class="text-center printable">

            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import FastSaleRequests from "../../../requests/dashboard/sale/FastSale";
import ProductsRequests from "../../../requests/dashboard/product/Products";
export default {
    data() {
        return {
            fastsalelist: [],
            search: '',
            error_msg: null,
            success_msg: null,
            discount: 0,
            invoicenumber: Vue.prototype.customerInvoiceNumber(),
            date: Vue.prototype.today(),
            note: ".",
            customer_id: 1,
            invoicetype_id: Vue.prototype.setting().maininvoicetypesale == 'cash' ? 1 : 2,
            currency_id: Vue.prototype.setting().maincurrencysale == 'iqd' ? 1 : 2,
            store_id: (Vue.prototype.state().getdashboardauth.store_id != '' && Vue.prototype.state().getdashboardauth.store_id != null) ? Vue.prototype.state().getdashboardauth.store_id : 1,
            treasure_id: '',
            disableinvoice: true,
            disableall: false,
        };
    },
    mounted() {
        this.readData();
    },
    computed: {
        products() {
            return this.$store.getters.getproducts.filter(p =>
                !this.$store.getters.getfastsalelist.includes(p) && (p.ku_name.search(this.search) > -1 || (p.barcode).toString().search(this.search) > -1 || this.searchProductBarcodes(p.id))
            )
        },
        productcategories() {
            return this.$store.getters.getproductcategories
        },
        customers() {
            return this.isNormal() ? this.$store.getters.getcustomers.filter(c => { return c.id == 1 || c.user_id == this.userId() }) : this.$store.getters.getcustomers
        },
        invoicetypes() {
            return this.$store.getters.getinvoicetypes;
        },
        currencies() {
            return this.$store.getters.getcurrencies;
        },
        productbarcodes() {
            return this.$store.getters.getproductbarcodes;
        },
        stores() {
            return this.$store.getters.getstores;
        },
        treasures() {
            return this.$store.getters.gettreasures.filter(t => { return t.store_id == this.store_id })
        },
        storeproducts() {
            return this.$store.getters.getstoreproducts.filter(sp => { return sp.store_id == this.store_id })
        },
    },
    methods: {
        readData() {
            this.fastsalelist = this.$store.getters.getfastsalelist
            this.totalInvoice()
            this.totalProfit()
            this.treasure_id = this.treasures[0]['id']
            this.products.forEach(p => {
                this.storeproducts.filter(sp => { return (sp.product_id == p.id) }).forEach(sp => {
                    p.qty = parseFloat(sp.qty)
                    p.fastsaleqty = parseFloat(sp.qty)
                    p.fastbuyqty = parseFloat(sp.qty)
                    p.productslistingqty = parseFloat(sp.qty)
                })
            })
        },
        searchProductBarcodes(product_id) {
            return this.productbarcodes.filter(pb => { return pb.product_id === product_id }).filter(b => {
                return (b.barcode).toString().search(this.search) > -1
            })[0] != undefined
        },
        fetchProducts() {
            ProductsRequests.getAll({ getAllProducts: true }, (res) => {
                this.$store.state.products = res.data.data
                this.products.forEach(p => {
                    this.storeproducts.filter(sp => { return (sp.product_id == p.id) }).forEach(sp => {
                        p.qty = parseFloat(sp.qty)
                        p.fastsaleqty = parseFloat(sp.qty)
                        p.fastbuyqty = parseFloat(sp.qty)
                        p.productslistingqty = parseFloat(sp.qty)
                    })
                })
            })
        },
        SwalConfirmation(callback) {
            this.$swal({
                title: this.langkeyword('ARE_YOU_SURE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.langkeyword('YES'),
                cancelButtonText: this.langkeyword('NO'),
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    callback(result.value)
                }
            })
        },
        addProduct(id) {
            this.cleanMessages();
            let item = this.products.filter(p => {
                return p.id == id
            })
            let newItem = item[0]
            this.$store.state.fastsalelist.push(newItem)
            let fastsaleitem = this.$store.state.fastsalelist.filter(p => {
                return p.id == id
            })[0]
            fastsaleitem.unittype = 'one'
            fastsaleitem.fastsaleqty = parseFloat(1)
            ProductsRequests.getOneByStore({ getOneProductsByStore: true, product_id: newItem.id, store_id: this.store_id }, (res) => {
                if ((this.$store.state.dashboardauth.role_id == 1 || this.$store.state.dashboardauth.role_id == 2) && (this.setting().hidebuypricesale == 'no')) {
                    if (this.currency_id === 1) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword('BUYPRICE') + ':' + this.dinar(res.data.data.buy_price_iqd)
                    }
                    else {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword('BUYPRICE') + ':' + this.dollar(res.data.data.buy_price_usd)
                    }
                }
            })
        },
        deleteProduct(id) {
            this.cleanMessages();
            this.$store.state.fastsalelist = this.$store.state.fastsalelist.filter(item => {
                return item.id != id
            })
            this.readData()
        },
        plusQty(id) {
            let oldItem = this.$store.state.fastsalelist.filter(item => {
                return item.id === id
            })[0]
            if (parseFloat(oldItem.qty) >= parseFloat(parseFloat(oldItem.fastsaleqty) + 1)) {
                let newQty = parseFloat(oldItem.fastsaleqty)
                oldItem.fastsaleqty = newQty + 1
            }
        },
        minusQty(id) {
            let oldItem = this.$store.state.fastsalelist.filter(item => {
                return item.id === id
            })[0]
            let newQty = parseFloat(oldItem.fastsaleqty) - 1
            if (newQty > 0) {
                this.$store.state.fastsalelist.filter(item => {
                    return item.id === id
                })[0].fastsaleqty = parseFloat(newQty)
            }
            else {
                this.deleteProduct(id)
            }

        },
        searchProduct() {
            let foundedProduct = this.products.filter(b => {
                return (b.barcode).toString() === this.search
            })
            if (foundedProduct[0] != undefined && foundedProduct.length === 1) {
                this.addProduct(foundedProduct[0].id)
                this.search = ''
            }
        },
        emptyAll() {
            this.$store.state.fastsalelist = []
            this.refreshAll()
            this.disableall = false
        },
        refreshAll() {
            this.invoicenumber = Vue.prototype.customerInvoiceNumber()
            this.search = ''
            this.readData()
        },
        totalInvoice() {
            if (this.currency_id == 1) {

                return this.fastsalelist.reduce(function (prev, p) {
                    return prev + (p.sale_price_iqd * parseFloat(p.fastsaleqty));
                }, 0) - this.discount
            }
            else {
                return this.fastsalelist.reduce(function (prev, p) {
                    return prev + (p.sale_price_usd * parseFloat(p.fastsaleqty));
                }, 0) - this.discount
            }
        },
        saveInvoiceConfirmation() {
            if (this.setting().hidesaveconfirmationsale == 'no') {
                this.SwalConfirmation(c => {
                    if (c === true) {
                        this.disableall = true
                        this.saveInvoice()
                        this.fetchProducts()
                    }
                })
            }
            else {
                this.disableall = true
                this.saveInvoice()
                this.fetchProducts()
            }
        },
        returnInvoiceConfirmation() {
            this.SwalConfirmation(c => {
                if (c === true) {
                    this.disableall = true
                    this.returnInvoice()
                    this.fetchProducts()
                }
            })
        },
        printInvoiceConfirmation() {
            this.SwalConfirmation(c => {
                if (c === true) {
                    window.print()
                    //this.printInvoice()
                }
            })
        },
        emptyAllConfirmation() {
            if (this.setting().hidedeleteconfirmationsale == 'no') {
                this.SwalConfirmation(c => {
                    if (c === true) {
                        this.disableall = true
                        this.emptyAll()
                        this.fetchProducts()
                    }
                })
            }
            else {
                this.disableall = true
                this.emptyAll()
                this.fetchProducts()
            }
        },
        saveInvoice() {
            FastSaleRequests.save(
                {
                    saveFastSale: true,
                    invoicenumber: this.invoicenumber,
                    date: this.date,
                    note: this.note,
                    customer_id: this.customer_id,
                    currency_id: this.currency_id,
                    invoicetype_id: this.invoicetype_id,
                    discount: this.discount,
                    store_id: this.store_id,
                    treasure_id: this.treasure_id,
                    user_id: Vue.prototype.dashboardAuthId(),
                    items: this.fastsalelist
                },
                (res) => {
                    console.log(res.data)
                    this.disableall = false
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.$store.state.customerinvoiceitems = res.data.customerinvoiceitems;
                        this.$store.state.customerinvoices = res.data.customerinvoices;
                        this.$store.state.products = res.data.products;
                        this.$store.state.storeproducts = res.data.storeproducts;
                        this.refreshAll()
                        this.emptyAll()
                    }
                    this.cleanMessages();
                }
            );
        },
        returnInvoice() {
            FastSaleRequests.return(
                {
                    returnFastSale: true,
                    invoicenumber: this.invoicenumber,
                    date: this.date,
                    note: this.note,
                    customer_id: this.customer_id,
                    currency_id: this.currency_id,
                    invoicetype_id: this.invoicetype_id,
                    store_id: this.store_id,
                    treasure_id: this.treasure_id,
                    user_id: Vue.prototype.dashboardAuthId(),
                    items: this.fastsalelist
                },
                (res) => {
                    this.disableall = false
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.$store.state.customerinvoiceitems = res.data.customerinvoiceitems;
                        this.$store.state.customerinvoices = res.data.customerinvoices;
                        this.$store.state.products = res.data.products;
                        this.$store.state.storeproducts = res.data.storeproducts;
                        this.refreshAll()
                        this.emptyAll()
                    }
                    this.cleanMessages();
                }
            );
        },
        printInvoice() {
            window.print()
            this.saveInvoice()
        },
        cleanMessages() {
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        saveMainInvoice() {
            this.totalInvoice()
            this.totalProfit()
            this.disableinvoice = true
        },
        updateMainInvoice() {
            this.disableinvoice = false
        },
        totalProfit() {
            let total = 0
            if (this.currency_id == 2) {
                return this.fastsalelist.reduce(function (prev, fsp) {
                    if (fsp.unittype == 'one') {
                        total = ((fsp.sale_price_usd - fsp.buy_price_usd) * fsp.fastsaleqty) + 0
                    }
                    else {
                        total = ((fsp.sale_price_usd - fsp.buy_price_usd) * (fsp.fastsaleqty * fsp.unit_one_from_many)) + 0
                    }
                    return prev + total;
                }, 0)
            } else {
                return this.fastsalelist.reduce(function (prev, fsp) {
                    if (fsp.unittype == 'one') {
                        total = ((fsp.sale_price_iqd - fsp.buy_price_iqd) * fsp.fastsaleqty) + 0
                    }
                    else {
                        total = ((fsp.sale_price_iqd - fsp.buy_price_iqd) * (fsp.fastsaleqty * fsp.unit_one_from_many)) + 0
                    }
                    return prev + total;
                }, 0)
            }
        },
        changeFspQty(p, e) {
            if (e != undefined) {
                let oldItem = this.$store.state.fastsalelist.filter(item => {
                    return item.id === p.id
                })[0]
                if (p.qty >= parseFloat(e.value) && parseFloat(e.value) >= 0) {
                    oldItem.fastsaleqty = parseFloat(e.value)
                }
            }
        },
        changeFspSalePrice(p, e) {
            if (e != undefined) {
                let oldItem = this.$store.state.fastsalelist.filter(item => {
                    return item.id === p.id
                })[0]
                if (this.currency_id == 1) {
                    oldItem.sale_price_iqd = parseFloat(e.value)
                }
                else {
                    oldItem.sale_price_usd = parseFloat(e.value)
                }
            }
        },
        changeDiscount(e) {
            if (e != undefined) {
                if (e.value < 0) {
                    this.discount = 0
                }
            }
        },
        refreshProduct(id, index) {
            ProductsRequests.getOneByStore({ getOneProductsByStore: true, product_id: id, store_id: this.store_id }, (res) => {
                this.$store.state.products[index].qty = res.data.data.qty
                this.$store.state.fastsalelist[index].qty = res.data.data.qty
            })
        }

    },
};
</script>
<style scoped>
.container,
.container-fluid,
body {
    background: white;
}

.printtable {
    height: 600px;
    overflow-y: auto;
    overflow-x: auto;
}

@media only screen and (max-width: 1750px) {
    .printtable {
        zoom: 90%;
    }
}

@media print {
    .printtable {
        height: auto;
        overflow: hidden;
    }

    .notprintable {
        display: none;
    }

    body,
    template {
        background: white;
    }
}
</style>