<template>
  <div>
    <navbar></navbar>
    <v-container fluid class="mb-4">
      <v-row class="alert mt-4">
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg != null">
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg != null">
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-row class="text-center printable">
        <v-col cols="12 text-start mx-auto" sm="12">
          <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
            class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
            <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
            {{ langkeyword("BACK") }}
          </v-btn>
        </v-col>
      </v-row>


      <v-row class="text-center">
        <v-col cols="12 text-start mx-auto" sm="12">
          <center>
            <v-card outlined class="ma-auto text-center mb-3" elevation="1">
              <v-form class="pa-3 text-center">
                <v-row>
                  <v-col cols="col-10 col-sm-6 col-md-3 col-lg-3 col-xl-2">
                    <v-text-field type="number" min="0" step="any" disabled outlined dense hide-details
                      class="nzar-font" :value="$store.state.currentcompanyinvoicedetail.invoicenumber
                        " :label="langkeyword('INVOICENUMBER')">
                    </v-text-field>
                  </v-col>
                  <v-col cols="col-10 col-sm-6 col-md-3 col-lg-3 col-xl-2">
                    <v-text-field type="date" disabled outlined dense hide-details class="nzar-font"
                      :value="$store.state.currentcompanyinvoicedetail.date" :label="langkeyword('DATE')">
                    </v-text-field>
                  </v-col>
                  <v-col cols="col-10 col-sm-6 col-md-3 col-lg-3 col-xl-2">
                    <v-text-field disabled outlined dense hide-details class="nzar-font" append-icon="mdi-format-title"
                      :value="$store.state.currentcompanyinvoicedetail.note" :label="langkeyword('NOTE')">
                    </v-text-field>
                  </v-col>
                  <v-col cols="col-10 col-sm-6 col-md-3 col-lg-3 col-xl-2"
                    v-if="setting().hidedollarbuy == 'no' && setting().hidedinarbuy == 'no'">
                    <v-autocomplete :value="$store.state.currentcompanyinvoicedetail.currency_id
                      " :items="currencies" :item-text="langoptions('value') + '_name'" item-value="id"
                      :label="langkeyword('CURRENCY')" disabled outlined hide-details class="nzar-font"
                      dense></v-autocomplete>
                  </v-col>
                  <v-col cols="col-10 col-sm-6 col-md-3 col-lg-3 col-xl-2">
                    <v-autocomplete :value="$store.state.currentcompanyinvoicedetail.company_id
                      " :items="companies" item-text="name" item-value="id" :label="langkeyword('COMPANY')" disabled
                      outlined hide-details class="nzar-font" dense></v-autocomplete>
                  </v-col>
                  <v-col cols="col-10 col-sm-6 col-md-3 col-lg-3 col-xl-2" v-if="setting().hideinvoicetypebuy == 'no'">
                    <v-autocomplete :value="$store.state.currentcompanyinvoicedetail.invoicetype_id
                      " :items="invoicetypes" :item-text="langoptions('value') + '_name'" item-value="id"
                      :label="langkeyword('INVOICETYPE')" disabled outlined hide-details class="nzar-font"
                      dense></v-autocomplete>
                  </v-col>
                  <v-col cols="col-10 col-sm-6 col-md-3 col-lg-3 col-xl-2">
                    <v-autocomplete :value="$store.state.currentcompanyinvoicedetail.store_id
                      " :items="stores" :item-text="langoptions('value') + '_name'" item-value="id"
                      :label="langkeyword('STORE')" disabled outlined hide-details
                      :class="isNormal() ? 'd-none' : 'nzar-font'" dense></v-autocomplete>
                  </v-col>
                  <v-col cols="col-10 col-sm-6 col-md-3 col-lg-3 col-xl-2">
                    <v-autocomplete :value="$store.state.currentcompanyinvoicedetail.treasure_id
                      " :items="treasures" :item-text="langoptions('value') + '_name'" item-value="id"
                      :label="langkeyword('TREASURE')" disabled outlined hide-details class="nzar-font"
                      dense></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider></v-divider>
              <v-form @submit.prevent="changeAvatar" ref="form" lazy-validation class="mt-3 text-center pa-1">
                <v-row>
                  <v-col cols="col-10 col-sm-6 col-md-3 col-lg-3 col-xl-2">
                    <v-file-input v-model="avatar" id="invoiceAvatar" outlined dense name="avatar"
                      :label="langkeyword('INVOICEIMAGE')" required prepend-icon="mdi-camera"></v-file-input>
                  </v-col>
                  <v-btn :disabled="avatar == null" type="submit" class="bg-main mt-3 m-auto white--text">
                    {{ langkeyword("UPDATE") }}
                  </v-btn>
                  <a class="mt-1 pa-2" target="_blank" :href="uploadPath(image)">
                    <v-btn v-if="image != '' && image != null && image != undefined
                    " rounded class="white--text bg-main">
                      <v-icon> mdi-image</v-icon>
                      {{ langkeyword("IMAGE") }}
                    </v-btn>
                  </a>
                </v-row>
              </v-form>
            </v-card>
          </center>
        </v-col>
      </v-row>




      <v-row class="text-main justify-center">
        <v-col cols="12 printable">
          <v-data-table :headers="shownHeaders" :items="companyinvoiceitems" :search="search" :sort-desc="true"
            sort-by="id" class="elevation-1 nzar-font rounded" :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }" :items-per-page="10">
            <template v-slot:top>
              <v-toolbar dense floating flat>
                <v-toolbar-title><v-icon>mdi-shopping</v-icon>
                  {{ langkeyword("PRODUCTS_LIST") }}</v-toolbar-title>
                <v-dialog v-model="dialog" max-width="1000px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success" style="color: white; float: left" class="radius-5 ma-2 nzar-font"
                      v-bind="attrs" v-on="on">
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("PURCHASE") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode" outlined>
                    <v-card-title>
                      <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span>
                      <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span>
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>
                          <v-autocomplete class="mt-2 mb-2" v-if="isAdd() === true || isEdit() === true"
                            @change="fetchProductByProduct($event)" v-model="companyinvoiceitemsObject.product_id"
                            :items="products" :item-text="langoptions('value') + '_name'" item-value="id"
                            :label="langkeyword('PRODUCT')" outlined hide-details hide-spin-buttons
                            full-width></v-autocomplete>

                          <v-text-field type="text" v-model="barcode" class="nzar-font mt-2 mb-2"
                            @keyup="fetchProductByBarcode($event)" :label="langkeyword('BARCODE')" outlined hide-details
                            hide-spin-buttons full-width>
                          </v-text-field>

                          <v-card v-if="
                            companyinvoiceitemsObject.product_id != null &&
                            companyinvoiceitemsObject.product_id != '' && productSelected
                          ">
                            <v-card-title class="bg-main darken-1 mx-auto">
                              <span class="white--text">{{
                                langkeyword("PRODUCT")
                              }}</span>
                            </v-card-title>


                            <div class="col-12" v-if="productinfo.avatar != '' && productinfo.avatar != null">
                              <v-img width="200px" height="200px" class="mx-auto rounded-lg"
                                :src="uploadPath(productinfo.avatar)">
                              </v-img>
                            </div>
                            <div v-if="setting().hidedinarbuy == 'no'" class="pa-2 ma-1">
                              <h2>{{ langkeyword("SALEPRICE") }} ــ {{
                                langkeyword("IQD") }} : {{
                                  productinfo.sale_price_iqd }}</h2>
                            </div>
                            <div v-if="setting().hidedollarbuy == 'no'" class="pa-2 ma-1">
                              <h2>{{ langkeyword("SALEPRICE") }} ــ {{
                                langkeyword("USD") }} : {{
                                  productinfo.sale_price_usd }}</h2>
                            </div>
                            <div v-if="setting().hidedinarbuy == 'no'" class="pa-2 ma-1">
                              <h2>{{ langkeyword("BUYPRICE") }} ــ {{
                                langkeyword("IQD") }} : {{
                                  productinfo.buy_price_iqd }}</h2>
                            </div>
                            <div v-if="setting().hidedollarbuy == 'no'" class="pa-2 ma-1">
                              <h2>{{ langkeyword("BUYPRICE") }} ــ {{
                                langkeyword("USD") }} : {{
                                  productinfo.buy_price_usd }}</h2>
                            </div>
                            <div v-if="setting().hideprofitbuy == 'no'" class="pa-2 ma-1">
                              <h2>{{ langkeyword("PROFIT") }}-{{
                                langkeyword("UNITONE") }} : {{
                                  profitOne }}</h2>
                            </div>


                            <div v-if="setting().hideunitbuy == 'no' && productinfo.unit_many != 1" class="pa-2 ma-1">
                              <h2>{{ langkeyword("UNITMANY") }} :

                                <v-chip class="bg-main white--text ma-1">
                                  {{
                                    productinfo.qty /
                                    productinfo.unit_one_from_many
                                  }}
                                </v-chip>
                                <v-chip class="bg-main white--text ma-1">
                                  <span v-if="lang() === 'ku'">{{
                                    productinfo.unitsmany_ku_name
                                  }}</span>
                                  <span v-if="lang() === 'ar'">{{
                                    productinfo.unitsmany_ar_name
                                  }}</span>
                                  <span v-if="lang() === 'en'">{{
                                    productinfo.unitsmany_en_name
                                  }}</span>
                                </v-chip>
                              </h2>
                            </div>

                            <div v-if="setting().hideunitbuy == 'no' && productinfo.unit_many != 1" class="pa-2 ma-1">
                              <h2>{{ langkeyword("UNITONEFROMMANY") }} :
                                <v-chip class="bg-main white--text ma-1">
                                  {{ productinfo.unit_one_from_many }}
                                </v-chip>
                              </h2>
                            </div>

                            <div class="pa-2 ma-1">
                              <h2>{{ langkeyword("QUANTITY") }} :
                                <v-chip class="bg-main white--text ma-1">
                                  {{ productinfo.qty }}
                                </v-chip>
                                <v-chip class="bg-main white--text ma-1">
                                  <span v-if="lang() === 'ku'">{{
                                    productinfo.unitsone_ku_name
                                  }}</span>
                                  <span v-if="lang() === 'ar'">{{
                                    productinfo.unitsone_ar_name
                                  }}</span>
                                  <span v-if="lang() === 'en'">{{
                                    productinfo.unitsone_en_name
                                  }}</span>
                                </v-chip>
                              </h2>
                            </div>


                          </v-card>

                          <center
                            v-if="productSelected && setting().hideaveragebuy == 'no' && companyinvoiceitemsObject.buy_price != 0 && companyinvoiceitemsObject.buy_price != '' && companyinvoiceitemsObject.buy_price != null">
                            <h2 class="mt-1 pa-2" v-if="companyInvoiceDetail('currency_id') == 1">{{
                              langkeyword('BUY_PRICE_AVG') }}: {{
                                dinar(avgbuyprice) }}</h2>
                            <h2 class="mt-1 pa-2" v-if="companyInvoiceDetail('currency_id') == 2">{{
                              langkeyword('BUY_PRICE_AVG') }}: {{
                                dollar(avgbuyprice) }}</h2>
                          </center>

                          <br />
                          <h3 v-if="productSelected">{{ setting().hideunitbuy == 'no' && productinfo.unit_many != 1 ?
                            langkeyword('UNITMANY') :
                            langkeyword('QUANTITY')
                            }}</h3>
                          <v-text-field v-if="productSelected" type="number" outlined hide-details hide-spin-buttons
                            full-width min="0" step="any" class="nzar-font ma-2"
                            v-model="companyinvoiceitemsObject.unit_many_qty"
                            :label="setting().hideunitbuy == 'no' && productinfo.unit_many != 1 ? langkeyword('UNITMANY') : langkeyword('QUANTITY')"
                            @keyup="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @click="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @keydown="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @keypress="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @input="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @mouseleave="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @mouseenter="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @mousewheel="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)">
                          </v-text-field>
                          <br>
                          <h3 v-if="productSelected && setting().hideunitbuy == 'no' && productinfo.unit_many != 1">{{
                            langkeyword('UNITONEFROMMANY')
                          }}</h3>
                          <v-text-field
                            v-if="productSelected && setting().hideunitbuy == 'no' && productinfo.unit_many != 1"
                            type="number" outlined hide-details hide-spin-buttons full-width min="0" step="any"
                            class="nzar-font ma-2" v-model="companyinvoiceitemsObject.unit_many_qty_inside
                              " :label="langkeyword('UNITONEFROMMANY')"
                            @keyup="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @click="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @keydown="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @keypress="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @input="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @mouseleave="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @mouseenter="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @mousewheel="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)">
                          </v-text-field>
                          <br>
                          <h3 v-if="productSelected">{{ langkeyword('BUYPRICE') }}</h3>
                          <v-text-field v-if="productSelected" type="number" outlined hide-details hide-spin-buttons
                            full-width min="0" step="any" class="nzar-font ma-2"
                            v-model="companyinvoiceitemsObject.buy_price" :label="langkeyword('BUYPRICE')"
                            @keyup="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @click="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @keydown="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @keypress="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @input="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @mouseleave="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @mouseenter="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)"
                            @mousewheel="fetchNewSalePrice(companyinvoiceitemsObject.unit_many_qty, companyinvoiceitemsObject.unit_many_qty_inside, companyinvoiceitemsObject.buy_price)">
                          </v-text-field>

                          <v-spacer></v-spacer>

                          <h3 v-if="productSelected">{{ langkeyword('SALEPRICE') + ' ــ ' + langkeyword('IQD') }}</h3>
                          <v-text-field v-if="productSelected" type="number" outlined hide-details hide-spin-buttons
                            full-width min="0" step="any" class="nzar-font ma-2" v-model="sale_price_iqd"
                            :label="langkeyword('SALEPRICE') + ' ــ ' + langkeyword('IQD')">
                          </v-text-field>
                          <h3 v-if="productSelected">{{ langkeyword('SALEPRICE') + ' ــ ' + langkeyword('USD') }}</h3>
                          <v-text-field v-if="productSelected" type="number" outlined hide-details hide-spin-buttons
                            full-width min="0" step="any" class="nzar-font ma-2" v-model="sale_price_usd"
                            :label="langkeyword('SALEPRICE') + ' ــ ' + langkeyword('USD')">
                          </v-text-field>


                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="bg-main white--text" v-if="isAdd() === true" dark @click="saveCompanyInvoiceItems">
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isEdit() === true" dark @click="
                        updateCompanyInvoiceItems(
                          companyinvoiceitemsObject.id
                        )
                        ">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="red lighten-2" dark @click="
                        deleteCompanyInvoiceItems(
                          companyinvoiceitemsObject.id
                        )
                        ">
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row class="d-none">
                  <v-col cols="12 text-center mx-auto" md="10">
                    <v-text-field class="nzar-font" v-model="search" append-icon="mdi-magnify"
                      :label="langkeyword('SEARCH')" hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <!--  -->
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon small class="mr-2 orange white--text px-2 py-2 rounded" @click="editDialog(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon small class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>









      <v-row>
        <v-col cols="12 text-start mx-auto" sm="12" md="6" lg="6" xl="6">
          <v-card outlined elevation="1">
            <v-simple-table class="pa-1 text-center">
              <thead>
                <tr>
                  <th class="text-center ma-auto">
                    <h2><v-icon>mdi-cash-multiple</v-icon> {{ langkeyword('TOTAL') }}:</h2>
                  </th>
                  <th class="text-center ma-auto">
                    <h2 v-if="companyInvoiceDetail('currency_id') == 1">{{ dinar(totalinvoice) }}</h2>
                    <h2 v-if="companyInvoiceDetail('currency_id') == 2">{{ dollar(totalinvoice) }}</h2>
                  </th>
                </tr>
                <tr v-if="setting().hidediscountbuy == 'no'">
                  <th class="text-center ma-auto">
                    <h2><v-icon>mdi-brightness-percent</v-icon> <v-btn class="bg-main white--text" small
                        @click="discountdialog = true">{{ langkeyword("DISCOUNT")
                        }}</v-btn>:</h2>
                  </th>
                  <th class="text-center ma-auto">
                    <h2 v-if="companyInvoiceDetail('currency_id') == 1">{{ dinar(companyInvoiceDetail("discount")) }}
                    </h2>
                    <h2 v-if="companyInvoiceDetail('currency_id') == 2">{{ dollar(companyInvoiceDetail("discount")) }}
                    </h2>
                  </th>
                </tr>
                <tr v-if="setting().hidedriverwagebuy == 'no'">
                  <th class="text-center ma-auto">
                    <h2><v-icon>mdi-brightness-percent</v-icon> <v-btn class="bg-main white--text" small
                        @click="driverwagedialog = true">{{
                          langkeyword("DRIVERWAGE")
                        }}</v-btn>
                      :</h2>
                  </th>
                  <th class="text-center ma-auto">
                    <h2 v-if="companyInvoiceDetail('currency_id') == 1">{{ dinar(companyInvoiceDetail("driverwage"))
                      }}
                    </h2>
                    <h2 v-if="companyInvoiceDetail('currency_id') == 2">{{ dollar(companyInvoiceDetail("driverwage"))
                      }}
                    </h2>
                  </th>
                </tr>
                <tr></tr>
              </thead>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="12 text-start mx-auto" sm="12" md="6" lg="6" xl="6">
          <v-card outlined elevation="1">
            <v-simple-table class="pa-1 text-center">
              <thead>
                <tr>
                  <th class="text-center ma-auto">
                    <h2><v-icon>mdi-cash-multiple</v-icon> {{ langkeyword('ALLTOTAL') }}:</h2>
                  </th>
                  <th class="text-center ma-auto">
                    <h2 v-if="companyInvoiceDetail('currency_id') == 1">{{ dinar(totalinvoice +
                      companyInvoiceDetail("driverwage") - companyInvoiceDetail("discount")) }}</h2>
                    <h2 v-if="companyInvoiceDetail('currency_id') == 2">{{ dollar(totalinvoice +
                      companyInvoiceDetail("driverwage") - companyInvoiceDetail("discount")) }}</h2>
                  </th>
                </tr>
                <tr>
                  <th class="text-center ma-auto">
                    <h2><v-icon>mdi-truck</v-icon> {{ langkeyword('SHIPPING') }}</h2>
                  </th>
                  <th class="text-center ma-auto">
                    <v-btn @click="shipping()" color="warning" class="white--text ma-2"
                      :disabled="companyInvoiceDetail('shipping') == 'ontheway'"><v-icon>mdi-truck-delivery</v-icon>
                      {{ langkeyword('ONTHEWAY')
                      }}</v-btn>
                    <v-btn @click="shipping()" color="success" class="white--text ma-2"
                      :disabled="companyInvoiceDetail('shipping') == 'delivered'"><v-icon>mdi-truck-check</v-icon> {{
                        langkeyword('DELIVERED')
                      }}</v-btn>
                  </th>
                </tr>
                <tr></tr>
              </thead>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>





      <v-row justify="center">
        <v-dialog v-model="discountdialog" persistent max-width="600px">
          <v-card class="text-center">
            <v-card-title>
              <span class="nzar-font">{{ langkeyword("TAKEDISCOUNT") }}</span>
            </v-card-title>
            <v-card-text>
              <v-col cols="12">
                <v-text-field type="number" min="0" step="any" v-model="discountvalue" :label="langkeyword('AMOUNT')"
                  required></v-text-field>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red lighten-1 white--text" @click="discountdialog = false">
                {{ langkeyword("CLOSE") }}
              </v-btn>
              <v-btn class="white--text bg-main" @click="discountAction">
                {{ langkeyword("OK") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="driverwagedialog" persistent max-width="600px">
          <v-card class="text-center">
            <v-card-title>
              <span class="nzar-font">{{ langkeyword("TAKEDRIVERWAGE") }}</span>
            </v-card-title>
            <v-card-text>
              <v-col cols="12">
                <v-text-field type="number" min="0" step="any" v-model="driverwagevalue" :label="langkeyword('AMOUNT')"
                  required></v-text-field>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red lighten-1 white--text" @click="driverwagedialog = false">
                {{ langkeyword("CLOSE") }}
              </v-btn>
              <v-btn class="white--text bg-main" @click="driverwageAction">
                {{ langkeyword("OK") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>

    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>

<script>
import Vue from "vue";
import ProductRequests from "../../../requests/dashboard/product/Products";
import CompanyInvoicesRequests from "../../../requests/dashboard/buy/CompanyInvoices";
import CompanyInvoiceItemsRequests from "../../../requests/dashboard/buy/CompanyInvoiceItems";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
// import products from "../../dashboard/product/products.vue";
export default {
  components: {
    navbar,
    dashboardFooter,
    // products,
  },
  data() {
    return {
      discountdialog: false,
      discount: 0,
      discountvalue: 0,
      productSelected: false,
      profitOne: 0,
      driverwagedialog: false,
      driverwage: 0,
      driverwagevalue: 0,
      dialog: false,
      dialogDelete: false,
      snackbar: true,
      companyinvoiceitems: Vue.prototype.getters().getcompanyinvoiceitems,
      totalinvoice: 0,
      productinfo: {},
      barcode: null,
      avgbuyprice: 0,
      sale_price_iqd: 0,
      sale_price_usd: 0,
      avatar: null,
      image: null,
      search: "",
      error_msg: null,
      success_msg: null,
      headers: [
        { text: "#", value: "id", align: "center", show: false },
        {
          text: Vue.prototype.langkeyword("PRODUCT"),
          value: "products_" + Vue.prototype.langoptions("value") + "_name",
          align: "center",
          show: true
        },
        {
          text: Vue.prototype.langkeyword("UNITMANY"),
          value: "unit_many_qty",
          align: "center",
          show: Vue.prototype.setting().hideunitbuy == 'no' ? true : false
        },
        {
          text: Vue.prototype.langkeyword("UNITONEFROMMANY"),
          value: "unit_many_qty_inside",
          align: "center",
          show: Vue.prototype.setting().hideunitbuy == 'no' ? true : false
        },
        {
          text: Vue.prototype.langkeyword("QUANTITY"),
          value: "qty",
          align: "center",
          show: true
        },
        {
          text: Vue.prototype.langkeyword("BUYPRICEONE"),
          value: "buy_price",
          align: "center",
          show: true
        },
        {
          text: Vue.prototype.langkeyword("TOTAL"),
          value: "total",
          align: "center",
          show: true
        },
        {
          text: Vue.prototype.langkeyword("ACTIONS"),
          value: "edit",
          sortable: false,
          align: "center",
          show: true
        },
      ],
      CURRENTFORM: "add",
      companyinvoiceitemsObject: {
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        companyinvoice_id: this.$route.params.id,
        product_id: "",
        unit_many_qty: 0,
        unit_many_qty_inside: 1,
        buy_price: 0,
        store_id: this.$store.state.currentcompanyinvoicedetail.store_id,
        treasure_id: this.$store.state.currentcompanyinvoicedetail.treasure_id,
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.readData();
      },
    },
  },
  mounted() {
    this.readData();
    setTimeout(() => {
      this.image = this.companyInvoiceDetail("avatar");
      this.discountvalue = this.discount;
      this.driverwagevalue = this.driverwage;
    }, 1000);
  },
  computed: {
    shownHeaders() {
      return this.headers.filter((h) => {
        if (h.show) {
          return h;
        }
      });
    },
    products() {
      return this.$store.getters.getproducts;
    },
    companies() {
      return this.isNormal() ? this.$store.getters.getcompanies.filter(c => { return c.id != 1 && c.user_id == this.userId() }) : this.$store.getters.getcompanies.filter(c => { return c.id != 1 })
    },
    invoicetypes() {
      return this.$store.getters.getinvoicetypes;
    },
    currencies() {
      return this.$store.getters.getcurrencies;
    },
    stores() {
      return this.$store.getters.getstores;
    },
    treasures() {
      return this.$store.getters.gettreasures;
    },
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true;
      } else {
        return false;
      }
    },
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    validate(params, callback) {
      this.error_msg = null;
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED");
        }
      });
      if (this.error_msg == null) {
        return callback();
      }
      this.cleanMessages();
    },
    editDialog(item) {
      this.fetchProductByProduct(item.product_id);
      this.CURRENTFORM = "edit";
      this.companyinvoiceitemsObject = Object.assign({
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        companyinvoice_id: this.$route.params.id,
        product_id: "",
        unit_many_qty: 0,
        unit_many_qty_inside: 1,
        buy_price: 0,
        store_id: this.$store.state.currentcompanyinvoicedetail.store_id,
        treasure_id: this.$store.state.currentcompanyinvoicedetail.treasure_id,
      }, item);
      this.dialog = true;
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock";
      this.companyinvoiceitemsObject = Object.assign({
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        companyinvoice_id: this.$route.params.id,
        product_id: "",
        unit_many_qty: 0,
        unit_many_qty_inside: 1,
        buy_price: 0,
        store_id: this.$store.state.currentcompanyinvoicedetail.store_id,
        treasure_id: this.$store.state.currentcompanyinvoicedetail.treasure_id,
      }, item);
      this.dialog = true;
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete";
      this.companyinvoiceitemsObject = Object.assign({
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        companyinvoice_id: this.$route.params.id,
        product_id: "",
        unit_many_qty: 0,
        unit_many_qty_inside: 1,
        buy_price: 0,
        store_id: this.$store.state.currentcompanyinvoicedetail.store_id,
        treasure_id: this.$store.state.currentcompanyinvoicedetail.treasure_id,
      }, item);
      this.dialogDelete = true;
    },
    closeMainDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.companyinvoiceitemsObject = Object.assign({
          id: "",
          user_id: Vue.prototype.dashboardAuthId(),
          companyinvoice_id: this.$route.params.id,
          product_id: "",
          unit_many_qty: 0,
          unit_many_qty_inside: 1,
          buy_price: 0,
          store_id: this.$store.state.currentcompanyinvoicedetail.store_id,
          treasure_id: this.$store.state.currentcompanyinvoicedetail.treasure_id,
        }, this.defaultItem);
        this.CURRENTFORM = "add";
      });
      this.barcode = ''
      this.productSelected = false
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.companyinvoiceitemsObject = Object.assign({
          id: "",
          user_id: Vue.prototype.dashboardAuthId(),
          companyinvoice_id: this.$route.params.id,
          product_id: "",
          unit_many_qty: 0,
          unit_many_qty_inside: 1,
          buy_price: 0,
          store_id: this.$store.state.currentcompanyinvoicedetail.store_id,
          treasure_id: this.$store.state.currentcompanyinvoicedetail.treasure_id,
        }, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    profitCalculate() {
      if (this.$store.state.currentcompanyinvoicedetail.currency_id == 2) {
        this.profitOne = (this.productinfo.sale_price_usd - this.productinfo.buy_price_usd);
      } else {
        this.profitOne = (this.productinfo.sale_price_iqd - this.productinfo.buy_price_iqd);
      }
    },
    discountAction() {
      if (this.discountvalue <= this.totalinvoice) {
        CompanyInvoicesRequests.discount(
          {
            discountCompanyInvoices: true,
            companyinvoice_id: this.$route.params.id,
            discount: this.discountvalue,
          },
          (res) => {
            if (res.data.status === false) {
              this.error_msg = this.langkeyword(res.data.msg);
              setTimeout(() => {
                this.$router.push("/*");
              }, 1000);
            } else if (res.data.status === true) {
              this.error_msg = null;
              this.$store.state.currentcompanyinvoicedetail = res.data.data;
              this.$store.state.companyinvoices.filter(ci => { return ci.id === res.data.data.id })[0].discount = res.data.data.discount
              this.discountvalue =
                this.$store.state.currentcompanyinvoicedetail.discount;
              this.discountdialog = false;
              this.productSelected = false
            }
            this.cleanMessages();
          }
        );
      } else {
        this.error_msg = this.langkeyword("NOT_VALID_AMOUNT");
        this.discountdialog = false;
      }
    },

    driverwageAction() {
      if (this.driverwagevalue <= this.totalinvoice) {
        CompanyInvoicesRequests.driverwage(
          {
            driverwageCompanyInvoices: true,
            companyinvoice_id: this.$route.params.id,
            driverwage: this.driverwagevalue,
          },
          (res) => {
            if (res.data.status === false) {
              this.error_msg = this.langkeyword(res.data.msg);
              setTimeout(() => {
                this.$router.push("/*");
              }, 1000);
            } else if (res.data.status === true) {
              this.error_msg = null;
              this.$store.state.currentcompanyinvoicedetail = res.data.data;
              this.$store.state.companyinvoices.filter(ci => { return ci.id === res.data.data.id })[0].driverwage = res.data.data.driverwage
              this.driverwagevalue =
                this.$store.state.currentcompanyinvoicedetail.driverwage;
              this.driverwagedialog = false;
              this.productSelected = false
            }
            this.cleanMessages();
          }
        );
      } else {
        this.error_msg = this.langkeyword("NOT_VALID_AMOUNT");
        this.driverwagedialog = false;
      }
    },
    fetchProductByProduct(productId) {
      ProductRequests.getOneByStore(
        {
          getOneProductsByStore: true,
          product_id: productId,
          store_id: this.$store.state.currentcompanyinvoicedetail.store_id,
        },
        (res) => {
          if (res.data.status === false) {
            this.productSelected = false
          } else if (res.data.status === true) {
            this.productSelected = false
            this.error_msg = null;
            this.productinfo = res.data.data;
            this.sale_price_iqd = this.productinfo.sale_price_iqd
            this.sale_price_usd = this.productinfo.sale_price_usd
            this.barcode = this.productinfo.barcode;
            this.companyinvoiceitemsObject.unit_many_qty_inside = this.productinfo.unit_one_from_many
            this.profitCalculate()
            this.productSelected = true
          }
          this.cleanMessages();
        }
      );
    },

    fetchProductByBarcode(event) {
      ProductRequests.barCodeByStore(
        {
          getOneProductsByStore: true,
          barcode: event.target.value,
          store_id: this.$store.state.currentcompanyinvoicedetail.store_id,
        },
        (res) => {
          if (res.data.status === false) {
            this.productSelected = false
          } else if (res.data.status === true) {
            this.productSelected = false
            this.error_msg = null;
            this.productinfo = res.data.data;
            this.sale_price_iqd = this.productinfo.sale_price_iqd
            this.sale_price_usd = this.productinfo.sale_price_usd
            this.companyinvoiceitemsObject.product_id = this.productinfo.id;
            this.profitCalculate()
            this.productSelected = true
          }
          this.cleanMessages();
        }
      );
    },

    changeAvatar() {
      const filename = document.getElementById("invoiceAvatar").files[0];
      const avatarParams = new FormData();
      avatarParams.append("changeAvatarCompanyInvoices", true);
      avatarParams.append("companyinvoice_id", this.$route.params.id);
      avatarParams.append("avatar", filename);
      const config = {
        headers: {
          "content-type": `multipart/form-data; boundary=${avatarParams._boundary}`,
        },
      };
      CompanyInvoicesRequests.changeAvatar(avatarParams, config, (res) => {
        if (res.data.status === false) {
          this.error_msg = this.langkeyword(res.data.msg);
        } else if (res.data.status === true) {
          document.getElementById("invoiceAvatar").value = null;
          this.avatar = null;
          this.image = res.data.avatar;
          this.error_msg = null;
          this.success_msg = this.langkeyword(res.data.msg);
          this.$store.state.currentcompanyinvoicedetail = res.data.data;
          this.$store.state.companyinvoices.filter(ci => { return ci.id === res.data.data.id })[0].avatar = res.data.data.avatar
          this.productSelected = false
        }
        this.cleanMessages();
      });
    },

    saveCompanyInvoiceItems() {
      this.validate(
        [
          this.$route.params.id,
          this.companyinvoiceitemsObject.product_id,
          this.companyinvoiceitemsObject.unit_many_qty,
          this.companyinvoiceitemsObject.unit_many_qty_inside,
          this.companyinvoiceitemsObject.buy_price,
          this.companyinvoiceitemsObject.store_id,
          this.companyinvoiceitemsObject.treasure_id,
          this.avgbuyprice,
        ],
        () => {
          CompanyInvoiceItemsRequests.add(
            {
              addCompanyInvoiceItems: true,
              companyinvoice_id: this.$route.params.id,
              product_id: this.companyinvoiceitemsObject.product_id,
              unit_many_qty: this.companyinvoiceitemsObject.unit_many_qty,
              unit_many_qty_inside:
                this.companyinvoiceitemsObject.unit_many_qty_inside,
              buy_price: this.companyinvoiceitemsObject.buy_price,
              store_id: this.companyinvoiceitemsObject.store_id,
              treasure_id: this.companyinvoiceitemsObject.treasure_id,
              user_id: Vue.prototype.dashboardAuthId(),
              avgbuyprice: this.avgbuyprice,
              sale_price_iqd: this.sale_price_iqd,
              sale_price_usd: this.sale_price_usd,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.products = res.data.products;
                this.$store.state.storeproducts = res.data.storeproducts;
                this.barcode = null
                this.readData();
                this.productSelected = false
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    updateCompanyInvoiceItems(companyinvoiceitemId) {
      console.log([
        this.$route.params.id,
        this.companyinvoiceitemsObject.product_id,
        this.companyinvoiceitemsObject.unit_many_qty,
        this.companyinvoiceitemsObject.unit_many_qty_inside,
        this.companyinvoiceitemsObject.buy_price,
        this.companyinvoiceitemsObject.store_id,
        this.companyinvoiceitemsObject.treasure_id,
        this.avgbuyprice,
      ])
      this.validate(
        [
          this.$route.params.id,
          this.companyinvoiceitemsObject.product_id,
          this.companyinvoiceitemsObject.unit_many_qty,
          this.companyinvoiceitemsObject.unit_many_qty_inside,
          this.companyinvoiceitemsObject.buy_price,
          this.companyinvoiceitemsObject.store_id,
          this.companyinvoiceitemsObject.treasure_id,
        ],
        () => {
          CompanyInvoiceItemsRequests.edit(
            {
              editCompanyInvoiceItems: true,
              companyinvoiceitem_id: companyinvoiceitemId,
              companyinvoice_id: this.$route.params.id,
              product_id: this.companyinvoiceitemsObject.product_id,
              unit_many_qty: this.companyinvoiceitemsObject.unit_many_qty,
              unit_many_qty_inside:
                this.companyinvoiceitemsObject.unit_many_qty_inside,
              buy_price: this.companyinvoiceitemsObject.buy_price,
              store_id: this.companyinvoiceitemsObject.store_id,
              treasure_id: this.companyinvoiceitemsObject.treasure_id,
              avgbuyprice: this.avgbuyprice,
              sale_price_iqd: this.sale_price_iqd,
              sale_price_usd: this.sale_price_usd,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.products = res.data.products;
                this.$store.state.storeproducts = res.data.storeproducts;
                this.barcode = null
                this.readData();
                this.productSelected = false
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    deleteCompanyInvoiceItems(companyinvoiceitemId) {
      CompanyInvoiceItemsRequests.delete(
        {
          deleteCompanyInvoiceItems: true,
          companyinvoiceitem_id: companyinvoiceitemId,
        },
        (res) => {
          console.log(res.data)
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.products = res.data.products;
            this.$store.state.storeproducts = res.data.storeproducts;
            this.readData();
            this.productSelected = false
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },

    shipping() {
      CompanyInvoicesRequests.shipping(
        {
          shippingCompanyInvoices: true,
          companyinvoice_id: this.$route.params.id,
        },
        (res) => {
          console.log(res.data)
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.currentcompanyinvoicedetail = res.data.data;
            this.$store.state.storeproducts = res.data.storeproducts;
            this.readData();
            this.productSelected = false
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },

    companyInvoiceDetail(field) {
      let actField = "";
      if (field === "invoicetype") {
        if (this.langoptions("value") === "ku") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.invoicetypes_ku_name;
        } else if (this.langoptions("value") === "ar") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.invoicetypes_ar_name;
        } else if (this.langoptions("value") === "en") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.invoicetypes_en_name;
        }
      }
      if (field === "currency") {
        if (this.langoptions("value") === "ku") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.currencies_ku_name;
        } else if (this.langoptions("value") === "ar") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.currencies_ar_name;
        } else if (this.langoptions("value") === "en") {
          actField =
            this.$store.state.currentcompanyinvoicedetail.currencies_en_name;
        }
      } else if (field === "date") {
        actField = this.$store.state.currentcompanyinvoicedetail.date;
      } else if (field === "shipping") {
        actField = this.$store.state.currentcompanyinvoicedetail.shipping;
      } else if (field === "symbol") {
        actField =
          this.$store.state.currentcompanyinvoicedetail.currencies_symbol;
      } else if (field === "invoicenumber") {
        actField = this.$store.state.currentcompanyinvoicedetail.invoicenumber;
      } else if (field === "company") {
        actField = this.$store.state.currentcompanyinvoicedetail.companies_name;
      } else if (field === "company_id") {
        actField = this.$store.state.currentcompanyinvoicedetail.company_id;
      } else if (field === "currency_id") {
        actField = this.$store.state.currentcompanyinvoicedetail.currency_id;
      } else if (field === "total") {
        actField = this.$store.state.currentcompanyinvoicedetail.total;
      } else if (field === "avatar") {
        actField = this.$store.state.currentcompanyinvoicedetail.avatar;
      } else if (field === "discount") {
        this.discount = this.$store.state.currentcompanyinvoicedetail.discount;
        actField = this.$store.state.currentcompanyinvoicedetail.discount;
      } else if (field === "driverwage") {
        this.driverwage =
          this.$store.state.currentcompanyinvoicedetail.driverwage;
        actField = this.$store.state.currentcompanyinvoicedetail.driverwage;
      }
      return actField;
    },
    fetchNewSalePrice(unit_many_qty, unit_many_qty_inside, new_buy_price) {
      if (this.productSelected) {
        if (this.$store.state.currentcompanyinvoicedetail.currency_id == 2) {
          var totaloldqtyusd = this.productinfo.qty
          var totaloldusd = totaloldqtyusd * this.productinfo.buy_price_usd
          var totalnewqtyusd = unit_many_qty * unit_many_qty_inside
          var totalnewusd = totalnewqtyusd * new_buy_price
          var alltotalqtyusd = totaloldqtyusd + totalnewqtyusd
          var alltotalusd = totaloldusd + totalnewusd
          var avgusd = parseFloat((alltotalusd / alltotalqtyusd).toFixed(2))
          this.avgbuyprice = avgusd
        }
        else if (this.$store.state.currentcompanyinvoicedetail.currency_id == 1) {
          var totaloldqtyiqd = this.productinfo.qty
          var totaloldiqd = totaloldqtyiqd * this.productinfo.buy_price_iqd
          var totalnewqtyiqd = unit_many_qty * unit_many_qty_inside
          var totalnewiqd = totalnewqtyiqd * new_buy_price
          var alltotalqtyiqd = totaloldqtyiqd + totalnewqtyiqd
          var alltotaliqd = totaloldiqd + totalnewiqd
          var avgiqd = parseFloat((alltotaliqd / alltotalqtyiqd).toFixed(2))
          this.avgbuyprice = avgiqd
        }
      }
    },

    /*
    100qty+1000qty = 1100qty
100+1250 = 1350
    */

    readData() {
      CompanyInvoicesRequests.getOne(
        {
          getOneCompanyInvoices: true,
          companyinvoice_id: this.$route.params.id,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
            setTimeout(() => {
              this.$router.push("/*");
            }, 1000);
          } else if (res.data.status === true) {
            this.error_msg = null;

            if ((this.$store.state.dashboardauth.role_id != 1 && this.$store.state.dashboardauth.role_id != 2)
              && res.data.data.user_id != this.$store.state.dashboardauth.id) {
              setTimeout(() => {
                this.$router.push("/*");
              }, 1000);
            }
            this.$store.state.currentcompanyinvoicedetail = res.data.data;
            this.companyinvoiceitemsObject.store_id = this.$store.state.currentcompanyinvoicedetail.store_id
            this.companyinvoiceitemsObject.treasure_id = this.$store.state.currentcompanyinvoicedetail.treasure_id
            this.discountvalue =
              this.$store.state.currentcompanyinvoicedetail.discount;
            this.driverwagevalue =
              this.$store.state.currentcompanyinvoicedetail.driverwage;
          }
          this.cleanMessages();
        }
      ),
        CompanyInvoiceItemsRequests.getAll(
          {
            getAllCompanyInvoiceItems: true,
            companyinvoice_id: this.$route.params.id,
          },
          (res) => {
            if (res.data.status === true) {
              this.error_msg = null;
              this.$store.state.companyinvoiceitems = res.data.data;
              this.companyinvoiceitems = res.data.data;
              this.totalinvoice = res.data.totalinvoice;
            }
            this.cleanMessages();
          }
        );

      setTimeout(() => {
        if (!this.permissionExist("#view_companyinvoices")) {
          this.$router.push("/dashboard/home");
        }
      }, 500);
    },
  },
};
</script>
<style scoped>
@media print {
  .notprintable {
    display: none;
  }

  .printable {
    display: block;
  }
}
</style>