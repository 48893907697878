<template>
  <div>
    <navbar></navbar>
    <v-container class="mt-4 mb-4">

      <v-snackbar v-if="error_msg != null" :timeout="-1" :value="true" color="red accent-2">
        {{ error_msg }}
      </v-snackbar>
      <v-snackbar v-if="success_msg != null" :timeout="-1" :value="true" color="green">
        {{ success_msg }}
      </v-snackbar>


      <v-row class="d-flex text-main justify-center">
        <v-col cols="12">
          <v-data-table :headers="headers" :items="customerinvoices" :loading="loading" :search="search"
            :sort-desc="true" sort-by="id" class="elevation-1 nzar-font radius-15 mode" :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }" :items-per-page="10">
            <template v-slot:top>
              <h2>
                <v-icon class="ma-1">mdi-cart-minus</v-icon>
                {{ langkeyword("CUSTOMERINVOICES") }}
              </h2>
              <v-divider class="col-3 col-sm-3 col-md-2 col-lg-1 col-xl-1 mt-1"></v-divider>
              <v-toolbar flat class="radius-15">
                <v-dialog v-model="dialog" max-width="1000px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success"
                      :style="permissionExist('#add_customerinvoices') ? 'color: white;float:right' : 'display:none;'"
                      class="radius-5 nzar-font" v-bind="attrs" v-on="on">
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("ADD") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode" outlined>
                    <v-card-title>
                      <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span>
                      <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span>
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>
                          <v-autocomplete outlined v-if="isAdd() === true || isEdit() === true" append-icon="mdi-store"
                            v-model="customerinvoicesObject.store_id" :items="stores"
                            :item-text="langoptions('value') + '_name'" item-value="id" :label="langkeyword('STORE')"
                            :class="isNormal() ? 'd-none' : ''"></v-autocomplete>
                          <v-autocomplete outlined v-if="isAdd() === true || isEdit() === true" append-icon="mdi-cash"
                            v-model="customerinvoicesObject.treasure_id" :items="treasures"
                            :item-text="langoptions('value') + '_name'" item-value="id"
                            :label="langkeyword('TREASURE')"></v-autocomplete>

                          <v-text-field outlined type="number" min="0" step="any" class="nzar-font"
                            v-model="customerinvoicesObject.invoicenumber" :label="langkeyword('INVOICENUMBER')">
                          </v-text-field>
                          <v-text-field outlined type="date" class="nzar-font" v-model="customerinvoicesObject.date"
                            :label="langkeyword('DATE')">
                          </v-text-field>
                          <v-text-field outlined class="nzar-font" append-icon="mdi-format-title"
                            v-model="customerinvoicesObject.note" :label="langkeyword('NOTE')">
                          </v-text-field>

                          <v-autocomplete outlined
                            v-if="(isAdd() === true || isEdit() === true) && setting().hidedollarsale == 'no' && setting().hidedinarsale == 'no'"
                            v-model="customerinvoicesObject.currency_id" :items="currencies"
                            :item-text="langoptions('value') + '_name'" item-value="id"
                            :label="langkeyword('CURRENCY')"></v-autocomplete>

                          <v-autocomplete outlined
                            v-if="(isAdd() === true || isEdit() === true) && setting().hideinvoicetypesale == 'no'"
                            v-model="customerinvoicesObject.invoicetype_id" :items="invoicetypes"
                            :item-text="langoptions('value') + '_name'" item-value="id"
                            :label="langkeyword('INVOICETYPE')"></v-autocomplete>

                          <v-autocomplete outlined v-if="isAdd() === true || isEdit() === true"
                            v-model="customerinvoicesObject.customer_id" :items="customers" item-text="name"
                            item-value="id" :label="langkeyword('CUSTOMER')"></v-autocomplete>


                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="bg-main white--text" v-if="isAdd() === true" dark @click="saveCustomerInvoices">
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isEdit() === true" dark @click="
                        updateCustomerInvoices(customerinvoicesObject.id)
                        ">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="red lighten-2" dark @click="
                        deleteCustomerInvoices(customerinvoicesObject.id)
                        ">
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field outlined dense v-if="permissionExist('#search_customerinvoices')" class="nzar-font"
                      v-model="search" append-icon="mdi-magnify" :label="langkeyword('SEARCH')"
                      hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-slot:[`item.total`]="{ item }">
              {{
                item.currency_id === 1 ?
                  dinar((item.invoicetotal - item.discount) + item.driverwage) :
                  dollar((item.invoicetotal - item.discount) + item.driverwage)
              }}
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-icon v-if="permissionExist('#edit_customerinvoices')" small
                class="mr-2 orange white--text px-2 py-2 rounded" @click="editDialog(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon v-if="permissionExist('#view_customerinvoices')" small
                class="mr-2 blue white--text px-2 py-2 rounded" @click="viewInvoice(item)">
                mdi-eye
              </v-icon>
              <v-icon v-if="permissionExist('#print_customerinvoices')" small
                class="mr-2 purple white--text px-2 py-2 rounded" @click="printInvoice(item)">
                mdi-printer
              </v-icon>
              <v-icon v-if="permissionExist('#delete_customerinvoices')" small
                class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row v-if="permissionExist('#search_customers')">
        <v-col cols="11" class="ma-auto">
          <v-expansion-panels popout hover focusable>
            <v-expansion-panel>
              <v-expansion-panel-header class="mode" expand-icon="mdi-face-agent">
                {{ langkeyword("CUSTOMERS") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mode">
                <customers :shownavandfooter="false" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>

<script>
import Vue from "vue"
import CustomerInvoicesRequests from "../../../requests/dashboard/sale/CustomerInvoices"
import navbar from "../../../layouts/dashboard/nav.vue"
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue"
import customers from "../sale/customers.vue"
export default {
  components: {
    navbar,
    dashboardFooter,
    customers,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    snackbar: true,
    customerinvoices: [],
    search: "",
    loading:
      Vue.prototype.getters().getcustomerinvoices == null ||
        Vue.prototype.getters().getcustomerinvoices == "" ||
        Vue.prototype.getters().getcustomerinvoices == undefined
        ? true
        : false,
    error_msg: null,
    success_msg: null,
    headers: [
      { text: "#", value: "id", align: "center d-none" },
      {
        text: Vue.prototype.langkeyword("INVOICENUMBER"),
        value: "invoicenumber",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("DATE"),
        value: "date",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("CUSTOMER"),
        value: "customers_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("INVOICETYPE"),
        value: "invoicetypes_" + Vue.prototype.langoptions("value") + "_name",
        align: Vue.prototype.setting().hideinvoicetypesale == 'no' ? "center" : ' d-none',
      },
      {
        text: Vue.prototype.langkeyword("CURRENCY"),
        value: "currencies_" + Vue.prototype.langoptions("value") + "_name",
        align: (Vue.prototype.setting().hidedollarsale == 'no' && Vue.prototype.setting().hidedinarsale == 'no') ? "center" : ' d-none',
      },
      {
        text: Vue.prototype.langkeyword("ALLTOTAL"),
        value: "total",
        sortable: false,
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("ACTIONS"),
        value: "edit",
        sortable: false,
        align: "center",
      },
    ],
    CURRENTFORM: "add",
    customerinvoicesObject: {
      user_id: Vue.prototype.dashboardAuthId(),
      invoicenumber: Vue.prototype.customerInvoiceNumber(),
      date: Vue.prototype.today(),
      note: ".",
      customer_id: "",
      invoicetype_id: Vue.prototype.setting().maininvoicetypesale == 'cash' ? 1 : 2,
      currency_id: Vue.prototype.setting().maincurrencysale == 'iqd' ? 1 : 2,
      store_id: Vue.prototype.userStore(),
      treasure_id: '',
    },
  }),
  watch: {
    options: {
      handler() {
        this.readData()
      },
    },
  },
  mounted() {
    this.readData()
    setTimeout(() => {
      this.readData()
    }, 1000)
  },
  computed: {
    customers() {
      return this.isNormal() ? this.$store.getters.getcustomers.filter(c => { return c.id != 1 && c.user_id == this.userId() }) : this.$store.getters.getcustomers.filter(c => { return c.id != 1 })
    },
    invoicetypes() {
      return this.$store.getters.getinvoicetypes
    },
    currencies() {
      return this.$store.getters.getcurrencies
    },
    stores() {
      return this.$store.getters.getstores
    },
    treasures() {
      return this.$store.getters.gettreasures.filter(t => { return t.store_id == this.customerinvoicesObject.store_id })
    },
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true
      } else {
        return false
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true
      } else {
        return false
      }
    },
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null
        this.error_msg = null
      }, 3000)
    },
    validate(params, callback) {
      this.error_msg = null
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED")
        }
      })
      if (this.error_msg == null) {
        return callback()
      }
      this.cleanMessages()
    },
    editDialog(item) {
      this.CURRENTFORM = "edit"
      this.customerinvoicesObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          invoicenumber: Vue.prototype.customerInvoiceNumber(),
          date: Vue.prototype.today(),
          note: ".",
          customer_id: "",
          invoicetype_id: Vue.prototype.setting().maininvoicetypesale == 'cash' ? 1 : 2,
          currency_id: Vue.prototype.setting().maincurrencysale == 'iqd' ? 1 : 2,
          store_id: Vue.prototype.userStore(),
          treasure_id: '',
        },
        item
      )
      this.dialog = true
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock"
      this.customerinvoicesObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          invoicenumber: Vue.prototype.customerInvoiceNumber(),
          date: Vue.prototype.today(),
          note: ".",
          customer_id: "",
          invoicetype_id: Vue.prototype.setting().maininvoicetypesale == 'cash' ? 1 : 2,
          currency_id: Vue.prototype.setting().maincurrencysale == 'iqd' ? 1 : 2,
          store_id: Vue.prototype.userStore(),
          treasure_id: '',
        },
        item
      )
      this.dialog = true
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete"
      this.customerinvoicesObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          invoicenumber: Vue.prototype.customerInvoiceNumber(),
          date: Vue.prototype.today(),
          note: ".",
          customer_id: "",
          invoicetype_id: Vue.prototype.setting().maininvoicetypesale == 'cash' ? 1 : 2,
          currency_id: Vue.prototype.setting().maincurrencysale == 'iqd' ? 1 : 2,
          store_id: Vue.prototype.userStore(),
          treasure_id: '',
        },
        item
      )
      this.dialogDelete = true
    },
    closeMainDialog() {
      this.dialog = false
      this.$nextTick(() => {
        this.customerinvoicesObject = Object.assign(
          {
            user_id: Vue.prototype.dashboardAuthId(),
            invoicenumber: Vue.prototype.customerInvoiceNumber(),
            date: Vue.prototype.today(),
            note: ".",
            customer_id: "",
            invoicetype_id: Vue.prototype.setting().maininvoicetypesale == 'cash' ? 1 : 2,
            currency_id: Vue.prototype.setting().maincurrencysale == 'iqd' ? 1 : 2,
            store_id: Vue.prototype.userStore(),
            treasure_id: '',
          },
          this.defaultItem
        )
        this.CURRENTFORM = "add"
      })
    },
    closeDeleteDialog() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.customerinvoicesObject = Object.assign(
          {
            user_id: Vue.prototype.dashboardAuthId(),
            invoicenumber: Vue.prototype.customerInvoiceNumber(),
            date: Vue.prototype.today(),
            note: ".",
            customer_id: "",
            invoicetype_id: Vue.prototype.setting().maininvoicetypesale == 'cash' ? 1 : 2,
            currency_id: Vue.prototype.setting().maincurrencysale == 'iqd' ? 1 : 2,
            store_id: Vue.prototype.userStore(),
            treasure_id: '',
          },
          this.defaultItem
        )
        this.CURRENTFORM = "add"
      })
    },
    readData() {
      if (this.isDeveloper() || this.isAdmin()) {
        this.customerinvoices = this.$store.getters.getcustomerinvoices
      }
      else {
        this.customerinvoices = this.$store.getters.getcustomerinvoices.filter(ci => {
          if (ci.user_id == this.userId()) {
            return ci
          }
        })
      }
      setTimeout(() => {
        this.customerinvoicesObject.store_id = Vue.prototype.userStore()
        //this.customerinvoicesObject.treasure_id = this.treasures[0]['id']
        if (!this.permissionExist("#view_customerinvoices")) {
          this.$router.push("/dashboard/home")
        }
      }, 500)
    },
    saveCustomerInvoices() {
      this.validate(
        [
          this.customerinvoicesObject.invoicenumber,
          this.customerinvoicesObject.date,
          this.customerinvoicesObject.note,
          this.customerinvoicesObject.customer_id,
          this.customerinvoicesObject.invoicetype_id,
          this.customerinvoicesObject.currency_id,
          this.customerinvoicesObject.store_id,
          this.customerinvoicesObject.treasure_id,
        ],
        () => {
          CustomerInvoicesRequests.add(
            {
              addCustomerInvoices: true,
              invoicenumber: this.customerinvoicesObject.invoicenumber,
              date: this.customerinvoicesObject.date,
              note: this.customerinvoicesObject.note,
              customer_id: this.customerinvoicesObject.customer_id,
              invoicetype_id: this.customerinvoicesObject.invoicetype_id,
              currency_id: this.customerinvoicesObject.currency_id,
              store_id: this.customerinvoicesObject.store_id,
              treasure_id: this.customerinvoicesObject.treasure_id,
              user_id: Vue.prototype.dashboardAuthId(),
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg)
              } else if (res.data.status === true) {
                this.error_msg = null
                this.success_msg = this.langkeyword(res.data.msg)
                this.$store.state.customerinvoices = res.data.data
                this.readData()
                this.$router.push(
                  "/dashboard/sale/customerinvoices/" + res.data.lastid
                )
                this.closeMainDialog()
              }
              this.cleanMessages()
            }
          )
        }
      )
    },
    updateCustomerInvoices(customerinvoiceId) {
      this.validate(
        [
          this.customerinvoicesObject.invoicenumber,
          this.customerinvoicesObject.date,
          this.customerinvoicesObject.note,
          this.customerinvoicesObject.customer_id,
          this.customerinvoicesObject.invoicetype_id,
          this.customerinvoicesObject.currency_id,
          this.customerinvoicesObject.store_id,
          this.customerinvoicesObject.treasure_id,
        ],
        () => {
          CustomerInvoicesRequests.edit(
            {
              editCustomerInvoices: true,
              customerinvoice_id: customerinvoiceId,
              invoicenumber: this.customerinvoicesObject.invoicenumber,
              date: this.customerinvoicesObject.date,
              note: this.customerinvoicesObject.note,
              customer_id: this.customerinvoicesObject.customer_id,
              invoicetype_id: this.customerinvoicesObject.invoicetype_id,
              currency_id: this.customerinvoicesObject.currency_id,
              store_id: this.customerinvoicesObject.store_id,
              treasure_id: this.customerinvoicesObject.treasure_id,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg)
              } else if (res.data.status === true) {
                this.error_msg = null
                this.success_msg = this.langkeyword(res.data.msg)
                this.$store.state.customerinvoices = res.data.data
                this.readData()
                this.closeMainDialog()
              }
              this.cleanMessages()
            }
          )
        }
      )
    },
    deleteCustomerInvoices(customerinvoiceId) {
      CustomerInvoicesRequests.delete(
        { deleteCustomerInvoices: true, customerinvoice_id: customerinvoiceId },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg)
          } else if (res.data.status === true) {
            this.error_msg = null
            this.success_msg = this.langkeyword(res.data.msg)
            this.$store.state.customerinvoices = res.data.data
            this.readData()
            this.closeDeleteDialog()
          }
          this.cleanMessages()
        }
      )
    },
    viewInvoice(item) {
      this.$router.push("/dashboard/sale/customerinvoices/" + item.id)
    },
    printInvoice(item) {
      this.$router.push("/dashboard/sale/customerinvoices/print/" + item.id)
    },
  },
}
</script>
