<template>
    <div>
        <navbar></navbar>
        <v-container class="mb-4">

            <v-row class="text-center printable">
                <v-col cols="12 text-start mx-auto" sm="12" md="12" lg="12" xl="12">
                    <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                        {{ langkeyword("BACK") }}
                    </v-btn>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{
                                langkeyword("PRODUCTPROFILE")
                                }}</h3>
                        </v-card-title>
                        <div class="pa-2" v-if="productinfo.data != undefined && productinfo.data != null">
                            <h4>{{ langkeyword("NAME") }} :
                                <span v-if="lang() === 'ku'">{{ productinfo.data.ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ productinfo.data.ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ productinfo.data.en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("BRAND") }} :
                                <span v-if="lang() === 'ku'">{{ productinfo.data.brands_ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ productinfo.data.brands_ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ productinfo.data.brands_en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("PRODUCTCATEGORY") }} :
                                <span v-if="lang() === 'ku'">{{ productinfo.data.productcategories_ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ productinfo.data.productcategories_ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ productinfo.data.productcategories_en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("UNITONE") }} :
                                <span v-if="lang() === 'ku'">{{ productinfo.data.unitsone_ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ productinfo.data.unitsone_ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ productinfo.data.unitsone_en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("UNITMANY") }} :
                                <span v-if="lang() === 'ku'">{{ productinfo.data.unitsmany_ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ productinfo.data.unitsmany_ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ productinfo.data.unitsmany_en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("UNITONEFROMMANY") }} : {{ productinfo.data.unit_one_from_many }}</h4>
                            <h4>{{ langkeyword("BARCODE") }} : {{ productinfo.data.barcode }}</h4>
                            <h4>{{ langkeyword("BUYPRICE") }} : {{ dinar(productinfo.data.buy_price_iqd) }}
                                &nbsp;&nbsp;-&nbsp;&nbsp; {{ dollar(productinfo.data.buy_price_usd) }}</h4>
                            <h4>{{ langkeyword("SALEPRICE") }} : {{ dinar(productinfo.data.sale_price_iqd) }}
                                &nbsp;&nbsp;-&nbsp;&nbsp; {{ dollar(productinfo.data.sale_price_usd) }}</h4>
                            <h4>{{ langkeyword('QUANTITY') }} :
                                {{ productinfo.quantities }}
                            </h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DINAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(productinfo.totalDinarBuy) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(productinfo.totalDinarBuyCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(productinfo.totalDinarBuyDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dinar(productinfo.totalDinarBuyFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DOLLAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(productinfo.totalDollarBuy) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(productinfo.totalDollarBuyCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(productinfo.totalDollarBuyDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dollar(productinfo.totalDollarBuyFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DINAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(productinfo.totalDinarSale) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(productinfo.totalDinarSaleCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(productinfo.totalDinarSaleDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dinar(productinfo.totalDinarSaleFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DOLLAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(productinfo.totalDollarSale) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(productinfo.totalDollarSaleCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(productinfo.totalDollarSaleDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dollar(productinfo.totalDollarSaleFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("PROFIT") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(productinfo.totaldollarprofit) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(productinfo.totaldollarprofitcash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(productinfo.totaldollarprofitdebt) }}</h4>
                            <v-divider></v-divider>
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(productinfo.totaldinarprofit) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(productinfo.totaldinarprofitcash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(productinfo.totaldinarprofitdebt) }}</h4>
                        </div>
                    </v-card>
                </v-col>
            </v-row>







            <v-row class="alert mt-4">
                <v-col class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-5 mx-auto text-center" v-if="error_msg != null">
                    <v-alert dense type="warning">{{ error_msg }}</v-alert>
                </v-col>
                <v-col class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-5 mx-auto text-center"
                    v-if="success_msg != null">
                    <v-alert dense type="success">{{ success_msg }}</v-alert>
                </v-col>
            </v-row>






            <v-expansion-panels class="container mt-5" popout hover focusable v-if="permissionExist('#edit_products')">
                <v-expansion-panel>
                    <v-expansion-panel-header class="mode rounded" expand-icon="mdi-store">
                        {{ langkeyword("STORES") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mode rounded">
                        <v-simple-table class="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ma-auto pa-2" dense>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        {{ langkeyword('STORE') }}
                                    </th>
                                    <th class="text-center">
                                        {{ langkeyword('QUANTITY') }}
                                    </th>
                                    <th class="text-center">
                                        {{ langkeyword('ACTIONS') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(storeproduct, index) in storeproducts" :key="index">
                                    <td class="text-center">
                                        <span v-if="lang() === 'ku'">{{ storeproduct.stores_ku_name }}</span>
                                        <span v-if="lang() === 'ar'">{{ storeproduct.stores_ar_name }}</span>
                                        <span v-if="lang() === 'en'">{{ storeproduct.stores_en_name }}</span>
                                    </td>
                                    <td class="text-center">
                                        <v-text-field v-if="editmode" type="number" hide-spin-buttons outlined dense
                                            full-width hide-details="" class="text-center nzar-font ma-1"
                                            v-model="storeproduct.qty" :label="langkeyword('BARCODE')">
                                        </v-text-field>
                                        <v-btn v-if="editmode" color="success" class="ma-1 pa-3 white--text" x-small
                                            @click="editProductQty(storeproduct.id, storeproduct.qty)"><v-icon>mdi-content-save</v-icon></v-btn>
                                        <span v-if="!editmode">{{ storeproduct.qty }}</span>
                                    </td>
                                    <td class="text-center">
                                        <v-btn v-if="!editmode" color="warning" class="ma-1 white--text" x-small
                                            @click="openEditMode()"><v-icon>mdi-pen</v-icon></v-btn>
                                        <v-btn v-if="editmode" color="red" class="ma-1 white--text" x-small
                                            @click="closeEditMode()"><v-icon>mdi-close</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>



            <v-expansion-panels class="container mt-5" popout hover focusable
                v-if="permissionExist('#transport_products')">
                <v-expansion-panel>
                    <v-expansion-panel-header class="mode rounded" expand-icon="mdi-transfer">
                        {{ langkeyword("TRANSFER") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mode rounded">
                        <v-form class="row text-center">
                            <v-card
                                class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 ma-auto pa-3 rounded mb-3 mt-5 mt-1 text-center"
                                outlined dense>
                                <v-autocomplete @change="to = null" v-model="from"
                                    :items="stores.filter(s => { return s.id != this.to })"
                                    :item-text="langoptions('value') + '_name'" item-value="id" clearable outlined
                                    class="" :label="langkeyword('FROM')" dense></v-autocomplete>
                                <v-autocomplete :disabled="from == '' || from === null" v-model="to"
                                    :items="stores.filter(s => { return s.id != this.from })"
                                    :item-text="langoptions('value') + '_name'" item-value="id" clearable outlined
                                    class="" :label="langkeyword('TO')" dense></v-autocomplete>
                                <v-text-field type="number" min="0" hide-spin-buttons outlined dense full-width
                                    hide-details="" class="nzar-font ma-1" v-model="quantity"
                                    :label="langkeyword('QUANTITY')">
                                </v-text-field>
                                <v-btn color="success" large class="ma-1 pa-2" @click="transferProduct()">
                                    <v-icon>mdi-transfer</v-icon>
                                    {{ langkeyword('TRANSFER') }}
                                </v-btn>
                            </v-card>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>




            <v-expansion-panels class="container mt-5" popout hover focusable
                v-if="permissionExist('#bracode_products')">
                <v-expansion-panel>
                    <v-expansion-panel-header class="mode rounded" expand-icon="mdi-barcode">
                        {{ langkeyword("BARCODE") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mode rounded">
                        <v-form class="row text-center">
                            <v-card
                                class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 ma-auto pa-3 rounded mb-3 mt-5 mt-1 text-center"
                                outlined dense>
                                <v-text-field type="text" hide-spin-buttons outlined dense full-width hide-details=""
                                    class="nzar-font ma-1" v-model="barcode" :label="langkeyword('BARCODE')">
                                </v-text-field>
                                <v-btn color="success" large class="ma-1 pa-2" @click="saveBarcode()">
                                    <v-icon>mdi-pen</v-icon>
                                    {{ langkeyword('SAVE') }}
                                </v-btn>
                            </v-card>
                        </v-form>
                        <v-simple-table class="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ma-auto pa-2" dense>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        {{ langkeyword('BARCODE') }}
                                    </th>
                                    <th class="text-center">
                                        {{ langkeyword('ACTIONS') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(productbarcode, index) in productbarcodes" :key="index">
                                    <td class="text-center">
                                        {{ productbarcode.barcode }}
                                    </td>
                                    <td class="text-center">
                                        <v-btn color="red" class="ma-1 white--text" x-small
                                            @click="deleteBarcode(productbarcode.id)"><v-icon>mdi-delete</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>







        </v-container>
        <br /><br /><br />
        <dashboardFooter />
    </div>
</template>

<script>
//import Vue from "vue"
import ProductsRequests from "../../../requests/dashboard/product/Products"
import ProductBarcodesRequests from "../../../requests/dashboard/product/ProductBarcodes"
import navbar from "../../../layouts/dashboard/nav.vue"
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue"
export default {
    components: {
        navbar,
        dashboardFooter,
    },
    data() {
        return {
            productinfo: {},
            error_msg: null,
            success_msg: null,
            barcode: '',
            quantity: 0,
            from: '',
            to: '',
            editmode: false
        }
    },
    watch: {
        options: {
            handler() {
                this.readData()
            },
        },
    },
    mounted() {
        this.readData()
    },
    computed: {
        productbarcodes() {
            return this.$store.getters.getproductbarcodes.filter(pb => { return pb.product_id == this.$route.params.id })
        },

        storeproducts() {
            return this.$store.getters.getstoreproducts.filter(sp => { return sp.product_id == this.$route.params.id })
        },
        stores() {
            return this.$store.getters.getstores
        },
    },
    methods: {
        cleanMessages() {
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        readData() {
            this.fetchProduct()
        },
        fetchProduct() {
            ProductsRequests.getOne(
                {
                    getOneProducts: true,
                    product_id: this.$route.params.id,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg)
                        setTimeout(() => {
                            this.$router.push("/*")
                        }, 1000)
                    } else if (res.data.status === true) {
                        this.error_msg = null
                        this.productinfo = res.data
                    }
                    this.cleanMessages()
                }
            ),
                setTimeout(() => {
                    if (!this.permissionExist("#view_products")) {
                        this.$router.push("/dashboard/home")
                    }
                }, 500)
        },
        saveBarcode() {
            if (this.barcode != null && this.barcode != '') {
                ProductBarcodesRequests.add(
                    {
                        addProductBarcodes: true,
                        barcode: this.barcode,
                        product_id: this.$route.params.id,
                    },
                    (res) => {
                        if (res.data.status === false) {
                            this.error_msg = this.langkeyword(res.data.msg);
                        } else if (res.data.status === true) {
                            this.error_msg = null;
                            this.success_msg = this.langkeyword(res.data.msg);
                            this.$store.state.productbarcodes = res.data.data;
                            this.barcode = null;
                            this.readData()
                        }
                        this.cleanMessages();
                    }
                );
            }
        },
        deleteBarcode(item) {
            ProductBarcodesRequests.delete(
                {
                    deleteProductBarcodes: true,
                    productbarcode_id: item,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.$store.state.productbarcodes = res.data.data;
                        this.barcode = null;
                        this.readData()
                    }
                    this.cleanMessages();
                }
            );
        },
        transferProduct() {
            if (this.from != null && this.from != '' && this.to != null && this.to != '' && this.quantity != null && this.quantity != '' && this.quantity != 0) {
                ProductsRequests.transfer(
                    {
                        transferProducts: true,
                        product_id: this.$route.params.id,
                        from: this.from,
                        to: this.to,
                        qty: this.quantity,
                    },
                    (res) => {
                        if (res.data.status === false) {
                            this.error_msg = this.langkeyword(res.data.msg);
                        } else if (res.data.status === true) {
                            this.error_msg = null;
                            this.success_msg = this.langkeyword(res.data.msg);
                            this.$store.state.storeproducts = res.data.data;
                            this.quantity = 0;
                            this.from = '';
                            this.to = '';
                            this.readData()
                        }
                        this.cleanMessages();
                    }
                );
            }
        },

        openEditMode() {
            this.editmode = true
        },
        closeEditMode() {
            this.editmode = false
        },
        editProductQty(storeproduct_id, qty) {
            this.editmode = true
            ProductsRequests.changeqty(
                {
                    changeQtyProducts: true,
                    storeproduct_id: storeproduct_id,
                    qty: qty,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg)
                    } else if (res.data.status === true) {
                        this.error_msg = null
                        this.$store.state.storeproducts = res.data.data
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.editmode = false
                    }
                    this.cleanMessages()
                }
            )
        }
    },
}
</script>
<style scoped>
@media print {
    .notprintable {
        display: none
    }

    .printable {
        display: block
    }
}
</style>