<template>
  <div>
    <navbar></navbar>
    <v-container class="mt-4 mb-4">

      <v-snackbar v-if="error_msg != null" :timeout="-1" :value="true" color="red accent-2">
        {{ error_msg }}
      </v-snackbar>
      <v-snackbar v-if="success_msg != null" :timeout="-1" :value="true" color="green">
        {{ success_msg }}
      </v-snackbar>

      <v-row class="d-flex text-main justify-center">
        <v-col cols="12">
          <v-data-table :headers="headers" :items="currencies" :loading="loading" :search="search" :sort-desc="true"
            sort-by="id" class="elevation-1 nzar-font radius-15 mode" :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }" :items-per-page="10">
            <template v-slot:top>
              <h2>
                <v-icon class="ma-1">mdi-currency-eur</v-icon>
                {{ langkeyword("CURRENCIES") }}
              </h2>
              <v-divider class="col-3 col-sm-3 col-md-2 col-lg-1 col-xl-1 mt-1"></v-divider>
              <v-toolbar flat class="radius-15">
                <v-dialog v-model="dialog" max-width="700px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success"
                      :style="permissionExist('#add_currencies') ? 'color: white;float:right' : 'display:none;'"
                      class="radius-5 nzar-font" v-bind="attrs" v-on="on">
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("ADD") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode" outlined>
                    <v-card-title>
                      <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span>
                      <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span>
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>
                          <v-text-field outlined class="nzar-font" append-icon="mdi-format-title"
                            v-model="currenciesObject.ku_name" :label="langkeyword('KU_NAME')">
                          </v-text-field>
                          <v-text-field outlined class="nzar-font" append-icon="mdi-format-title"
                            v-model="currenciesObject.ar_name" :label="langkeyword('AR_NAME')">
                          </v-text-field>
                          <v-text-field outlined class="nzar-font" append-icon="mdi-format-title"
                            v-model="currenciesObject.en_name" :label="langkeyword('EN_NAME')">
                          </v-text-field>

                          <v-text-field outlined type="number" min="0" step="any" class="nzar-font"
                            v-model="currenciesObject.price" :label="langkeyword('PRICE')">
                          </v-text-field>

                          <v-text-field outlined class="nzar-font" v-model="currenciesObject.symbol"
                            :label="langkeyword('SYMBOL')">
                          </v-text-field>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="bg-main white--text" v-if="isAdd() === true" dark @click="saveCurrencies">
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isEdit() === true" dark
                        @click="updateCurrencies(currenciesObject.id)">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="red lighten-2" dark @click="deleteCurrencies(currenciesObject.id)">
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field outlined dense v-if="permissionExist('#search_currencies')" class="nzar-font"
                      v-model="search" append-icon="mdi-magnify" :label="langkeyword('SEARCH')"
                      hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon v-if="permissionExist('#edit_currencies')" small class="mr-2 orange white--text px-2 py-2 rounded"
                @click="editDialog(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon v-if="permissionExist('#edit_currencies')" small class="mr-2 red white--text px-2 py-2 rounded"
                @click="deleteDialog(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>

<script>
import Vue from "vue"
import CurrenciesRequests from "../../../requests/dashboard/subs/Currencies"
import navbar from "../../../layouts/dashboard/nav.vue"
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue"
export default {
  components: {
    navbar,
    dashboardFooter,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    snackbar: true,
    currencies: [],
    search: "",
    loading:
      Vue.prototype.getters().getcurrencies == null ||
        Vue.prototype.getters().getcurrencies == "" ||
        Vue.prototype.getters().getcurrencies == undefined
        ? true
        : false,
    error_msg: null,
    success_msg: null,
    headers: [
      { text: "#", value: "id", align: "center" },
      {
        text: Vue.prototype.langkeyword("NAME"),
        value: Vue.prototype.langoptions("value") + "_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("PRICE"),
        value: "price",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("SYMBOL"),
        value: "symbol",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("ACTIONS"),
        value: "edit",
        sortable: false,
        align: "center",
      },
    ],
    CURRENTFORM: "add",
    currenciesObject: {
      user_id: Vue.prototype.dashboardAuthId(),
      ku_name: "",
      ar_name: "",
      en_name: "",
      price: "",
      symbol: "",
    },
  }),
  watch: {
    options: {
      handler() {
        this.readData()
      },
    },
  },
  mounted() {
    this.readData()
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true
      } else {
        return false
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true
      } else {
        return false
      }
    },
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null
        this.error_msg = null
      }, 3000)
    },
    validate(params, callback) {
      this.error_msg = null
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED")
        }
      })
      if (this.error_msg == null) {
        return callback()
      }
      this.cleanMessages()
    },
    editDialog(item) {
      this.CURRENTFORM = "edit"
      this.currenciesObject = Object.assign({}, item)
      this.dialog = true
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock"
      this.currenciesObject = Object.assign({}, item)
      this.dialog = true
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete"
      this.currenciesObject = Object.assign({}, item)
      this.dialogDelete = true
    },
    closeMainDialog() {
      this.dialog = false
      this.$nextTick(() => {
        this.currenciesObject = Object.assign({}, this.defaultItem)
        this.CURRENTFORM = "add"
      })
    },
    closeDeleteDialog() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.currenciesObject = Object.assign({}, this.defaultItem)
        this.CURRENTFORM = "add"
      })
    },
    readData() {
      this.currencies = this.$store.getters.getcurrencies
      setTimeout(() => {
        if (!this.permissionExist("#view_currencies")) {
          this.$router.push("/dashboard/home")
        }
      }, 500)
    },
    saveCurrencies() {
      this.validate(
        [
          this.currenciesObject.ku_name,
          this.currenciesObject.ar_name,
          this.currenciesObject.en_name,
          this.currenciesObject.price,
          this.currenciesObject.symbol,
        ],
        () => {
          CurrenciesRequests.add(
            {
              addCurrencies: true,
              ku_name: this.currenciesObject.ku_name,
              ar_name: this.currenciesObject.ar_name,
              en_name: this.currenciesObject.en_name,
              price: this.currenciesObject.price,
              symbol: this.currenciesObject.symbol,
              user_id: Vue.prototype.dashboardAuthId(),
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg)
              } else if (res.data.status === true) {
                this.error_msg = null
                this.success_msg = this.langkeyword(res.data.msg)
                this.$store.state.currencies = res.data.data
                this.currencies = res.data.data
                this.closeMainDialog()
              }
              this.cleanMessages()
            }
          )
        }
      )
    },
    updateCurrencies(currencyId) {
      this.validate(
        [
          this.currenciesObject.ku_name,
          this.currenciesObject.ar_name,
          this.currenciesObject.en_name,
          this.currenciesObject.price,
          this.currenciesObject.symbol,
        ],
        () => {
          CurrenciesRequests.edit(
            {
              editCurrencies: true,
              currency_id: currencyId,
              ku_name: this.currenciesObject.ku_name,
              ar_name: this.currenciesObject.ar_name,
              en_name: this.currenciesObject.en_name,
              price: this.currenciesObject.price,
              symbol: this.currenciesObject.symbol,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg)
              } else if (res.data.status === true) {
                this.error_msg = null
                this.success_msg = this.langkeyword(res.data.msg)
                this.$store.state.currencies = res.data.data
                this.currencies = res.data.data
                this.closeMainDialog()
              }
              this.cleanMessages()
            }
          )
        }
      )
    },
    deleteCurrencies(currencyId) {
      CurrenciesRequests.delete(
        { deleteCurrencies: true, currency_id: currencyId },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg)
          } else if (res.data.status === true) {
            this.error_msg = null
            this.success_msg = this.langkeyword(res.data.msg)
            this.$store.state.currencies = res.data.data
            this.currencies = res.data.data
            this.closeDeleteDialog()
          }
          this.cleanMessages()
        }
      )
    },
  },
}
</script>
