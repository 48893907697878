<template>
  <div>
    <navbar></navbar>
    <v-container class="mb-4">

      <v-snackbar v-if="error_msg != null" :timeout="-1" :value="true" color="red accent-2">
        {{ error_msg }}
      </v-snackbar>
      <v-snackbar v-if="success_msg != null" :timeout="-1" :value="true" color="green">
        {{ success_msg }}
      </v-snackbar>


      <v-row class="text-center printable">
        <v-col cols="11 text-start mx-auto" sm="12" md="12" lg="12" xl="12">
          <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
            class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
            <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
            {{ langkeyword("BACK") }}
          </v-btn>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
          <v-card elevation="2" outlined shaped>
            <v-card-title class="mx-auto">
              <h3>{{
                langkeyword("COMPANYPROFILE")
              }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword("NAME") }} : {{ companyinfo.name }}</h4>
              <h4>{{ langkeyword("PHONE") }} : {{ companyinfo.phone }}</h4>
              <h4>{{ langkeyword("ADDRESS") }} : {{ companyinfo.address }}</h4>
              <h4>{{ langkeyword("IQDDEBT") }} : {{ dinar(companyinfo.iqddebt) }}</h4>
              <h4>{{ langkeyword("USDDEBT") }} : {{ dollar(companyinfo.usddebt) }}</h4>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4"
          v-if="(companyinfo.totalDinarBuy != 0 || companyinfo.totalDollarBuy != 0) || (companyinfo.totalDinarExpense == 0 && companyinfo.totalDollarExpense == 0)">
          <v-card elevation="2" outlined shaped>
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DINAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword('TOTAL') }}: {{ dinar(companyinfo.totalDinarBuy) }}</h4>
              <h4>{{ langkeyword('CASH') }}: {{ dinar(companyinfo.totalDinarBuyCash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dinar(companyinfo.totalDinarBuyDebt) }}</h4>
              <h4>{{ langkeyword('FREE') }}: {{ dinar(companyinfo.totalDinarBuyFree) }}</h4>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4"
          v-if="(companyinfo.totalDinarBuy != 0 || companyinfo.totalDollarBuy != 0) || (companyinfo.totalDinarExpense == 0 && companyinfo.totalDollarExpense == 0)">
          <v-card elevation="2" outlined shaped>
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DOLLAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword('TOTAL') }}: {{ dollar(companyinfo.totalDollarBuy) }}</h4>
              <h4>{{ langkeyword('CASH') }}: {{ dollar(companyinfo.totalDollarBuyCash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dollar(companyinfo.totalDollarBuyDebt) }}</h4>
              <h4>{{ langkeyword('FREE') }}: {{ dollar(companyinfo.totalDollarBuyFree) }}</h4>
            </div>
          </v-card>
        </v-col>



        <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4"
          v-if="companyinfo.totalDinarExpense != 0 || companyinfo.totalDollarExpense != 0">
          <v-card elevation="2" outlined shaped>
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("EXPENSE") }}-{{ langkeyword("DINAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword('TOTAL') }}: {{ dinar(companyinfo.totalDinarExpense) }}</h4>
              <h4>{{ langkeyword('CASH') }}: {{ dinar(companyinfo.totalDinarExpenseCash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dinar(companyinfo.totalDinarExpenseDebt) }}</h4>
              <h4>{{ langkeyword('FREE') }}: {{ dinar(companyinfo.totalDinarExpenseFree) }}</h4>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4"
          v-if="companyinfo.totalDinarExpense != 0 || companyinfo.totalDollarExpense != 0">
          <v-card elevation="2" outlined shaped>
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("EXPENSE") }}-{{ langkeyword("DOLLAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword('TOTAL') }}: {{ dollar(companyinfo.totalDollarExpense) }}</h4>
              <h4>{{ langkeyword('CASH') }}: {{ dollar(companyinfo.totalDollarExpenseCash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dollar(companyinfo.totalDollarExpenseDebt) }}</h4>
              <h4>{{ langkeyword('FREE') }}: {{ dollar(companyinfo.totalDollarExpenseFree) }}</h4>
            </div>
          </v-card>
        </v-col>


      </v-row>



      <v-row>
        <v-col cols="12" class="ma-auto">
          <v-expansion-panels popout hover focusable>
            <v-expansion-panel>
              <v-expansion-panel-header class="mode" expand-icon="mdi-domain">
                {{ langkeyword("REPAID_DEBT") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mode">
                <v-row class="d-flex text-main justify-center">
                  <v-col cols="12 printable">
                    <v-data-table :headers="headers" :items="companyreturndebt" :search="search" :sort-desc="true"
                      sort-by="id" class="elevation-1 nzar-font radius-15 mode" :footer-props="{
                        showFirstLastPage: true,
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right',
                        'items-per-page-text': langkeyword('PERPAGE'),
                        'items-per-page-options': perPages(),
                      }" :items-per-page="10">
                      <template v-slot:top>
                        <v-toolbar flat class="radius-15">
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-dialog v-model="dialog" max-width="700px" persistent>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="success" style="color: white;float:right" class="mb-2 radius-5 nzar-font"
                                v-bind="attrs" v-on="on">
                                <v-icon> mdi-plus-circle </v-icon>
                                {{ langkeyword("REPAYING_DEBT") }}
                              </v-btn>
                            </template>
                            <v-card class="radius-15 bg-mode" outlined>
                              <v-card-title>
                                <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                                  {{ langkeyword("ADD") }}</span>
                                <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                                  {{ langkeyword("UPDATE") }}</span>
                              </v-card-title>
                              <v-card-text class="radius-15">
                                <v-container>
                                  <v-form>
                                    <v-autocomplete outlined v-if="isAdd() === true || isEdit() === true"
                                      v-model="companyreturndebtObject.treasure_id" :items="treasures"
                                      :item-text="langoptions('value') + '_name'" item-value="id"
                                      :label="langkeyword('TREASURE')"></v-autocomplete>

                                    <v-text-field outlined type="date" class="nzar-font"
                                      v-model="companyreturndebtObject.date" :label="langkeyword('DATE')">
                                    </v-text-field>

                                    <v-text-field outlined type="number" min="0" step="any" class="nzar-font"
                                      v-model="companyreturndebtObject.iqd_amount"
                                      :label="langkeyword('AMOUNT') + '-' + langkeyword('IQD')">
                                    </v-text-field>

                                    <v-text-field outlined type="number" min="0" step="any" class="nzar-font"
                                      v-model="companyreturndebtObject.usd_amount"
                                      :label="langkeyword('AMOUNT') + '-' + langkeyword('USD')">
                                    </v-text-field>
                                  </v-form>
                                </v-container>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn class="bg-main white--text" v-if="isAdd() === true" dark
                                  @click="saveCompanyReturnDebt">
                                  <v-icon dark> mdi-plus-circle</v-icon>
                                  {{ langkeyword("SAVE") }}
                                </v-btn>
                                <v-btn color="orange darken-1" v-if="isEdit() === true" dark @click="
                                  updateCompanyReturnDebt(companyreturndebtObject.id)
                                  ">
                                  <v-icon dark> mdi-pencil-circle</v-icon>
                                  {{ langkeyword("UPDATE") }}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" dark @click="closeMainDialog">
                                  <v-icon dark>mdi-close-circle</v-icon>
                                  {{ langkeyword("CLOSE") }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog persistent v-model="dialogDelete" max-width="500px">
                            <v-card>
                              <v-card-title>
                                <v-icon>mdi-delete-circle</v-icon>
                                {{ langkeyword("DELETE") }}
                              </v-card-title>
                              <v-card-text>
                                {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                              </v-card-text>
                              <v-card-actions>
                                <v-btn color="red lighten-2" dark @click="
                                  deleteCompanyReturnDebt(companyreturndebtObject.id)
                                  ">
                                  <v-icon>mdi-delete-circle</v-icon>
                                  {{ langkeyword("DELETE") }}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                                  <v-icon> mdi-close-circle</v-icon>
                                  {{ langkeyword("CLOSE") }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                          <v-spacer></v-spacer>

                          <v-row>
                            <v-col cols="12 text-center mx-auto" md="10">
                              <v-text-field class="nzar-font" v-model="search" append-icon="mdi-magnify"
                                :label="langkeyword('SEARCH')" hide-details></v-text-field>
                            </v-col>
                          </v-row>
                        </v-toolbar>
                      </template>
                      <!--  -->
                      <template v-slot:[`item.edit`]="{ item }">
                        <v-icon small class="mr-2 orange white--text px-2 py-2 rounded" @click="editDialog(item)">
                          mdi-pencil-circle
                        </v-icon>
                        <v-icon small class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                          mdi-delete-circle
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>




    </v-container>

    <companyInvoicesByCompany v-if="permissionExist('#view_companyinvoices')" :company_id="$route.params.id" />

    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>

<script>
import Vue from "vue";
import CompaniesRequests from "../../../requests/dashboard/debt/Companies";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
import companyInvoicesByCompany from "../../dashboard/buy/companyinvoicesbycompany.vue";
export default {
  components: {
    navbar,
    companyInvoicesByCompany,
    dashboardFooter,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      snackbar: true,
      companyinfo: {},
      companyreturndebt: Vue.prototype.getters().getcompanyreturndebt,
      search: "",
      error_msg: null,
      success_msg: null,
      headers: [
        { text: "#", value: "id", align: "center" },
        {
          text: Vue.prototype.langkeyword("TREASURE"),
          value: "treasures_" + Vue.prototype.langoptions("value") + "_name",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword('AMOUNT') + '-' + Vue.prototype.langkeyword('IQD'),
          value: "iqd_amount",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword('AMOUNT') + '-' + Vue.prototype.langkeyword('USD'),
          value: "usd_amount",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("DATE"),
          value: "date",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("ACTIONS"),
          value: "edit",
          sortable: false,
          align: "center",
        },
      ],
      CURRENTFORM: "add",
      companyreturndebtObject: {
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        company_id: this.$route.params.id,
        treasure_id: 1,
        date: Vue.prototype.today(),
        iqd_amount: 0,
        usd_amount: 0,
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.readData();
      },
    },
  },
  mounted() {

    this.readData();
  },
  computed: {
    treasures() {
      return this.$store.getters.gettreasures;
    },
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true;
      } else {
        return false;
      }
    },
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    validate(params, callback) {
      this.error_msg = null;
      params.forEach((param) => {
        if ((param == "" || param == undefined || param == null) && (param != 0)) {
          this.error_msg = this.langkeyword("DATA_REQUIRED");
        }
      });
      if (this.error_msg == null) {
        return callback();
      }
      this.cleanMessages();
    },
    editDialog(item) {
      this.CURRENTFORM = "edit";
      this.companyreturndebtObject = Object.assign({
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        company_id: this.$route.params.id,
        treasure_id: 1,
        date: "",
        iqd_amount: 0,
        usd_amount: 0,
      }, item);
      this.dialog = true;
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock";
      this.companyreturndebtObject = Object.assign({
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        company_id: this.$route.params.id,
        treasure_id: 1,
        date: "",
        iqd_amount: 0,
        usd_amount: 0,
      }, item);
      this.dialog = true;
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete";
      this.companyreturndebtObject = Object.assign({
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        company_id: this.$route.params.id,
        treasure_id: 1,
        date: "",
        iqd_amount: 0,
        usd_amount: 0,
      }, item);
      this.dialogDelete = true;
    },
    closeMainDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.companyreturndebtObject = Object.assign({
          id: "",
          user_id: Vue.prototype.dashboardAuthId(),
          company_id: this.$route.params.id,
          treasure_id: 1,
          date: "",
          iqd_amount: 0,
          usd_amount: 0,
        }, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.companyreturndebtObject = Object.assign({
          id: "",
          user_id: Vue.prototype.dashboardAuthId(),
          company_id: this.$route.params.id,
          treasure_id: 1,
          date: "",
          iqd_amount: 0,
          usd_amount: 0,
        }, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    saveCompanyReturnDebt() {
      this.validate(
        [
          this.$route.params.id,
          this.companyreturndebtObject.treasure_id,
          this.companyreturndebtObject.date,
          this.companyreturndebtObject.iqd_amount,
          this.companyreturndebtObject.usd_amount,
        ],
        () => {
          CompaniesRequests.returnDebt(
            {
              returnDebt: true,
              company_id: this.$route.params.id,
              treasure_id: this.companyreturndebtObject.treasure_id,
              date: this.companyreturndebtObject.date,
              iqd_amount: this.companyreturndebtObject.iqd_amount,
              usd_amount: this.companyreturndebtObject.usd_amount,
              user_id: Vue.prototype.dashboardAuthId(),
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.readData();
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    updateCompanyReturnDebt(companyreturndebtId) {
      this.validate(
        [
          this.$route.params.id,
          this.companyreturndebtObject.treasure_id,
          this.companyreturndebtObject.date,
          this.companyreturndebtObject.iqd_amount,
          this.companyreturndebtObject.usd_amount,
        ],
        () => {
          CompaniesRequests.editReturnDebt(
            {
              editReturnDebt: true,
              companyreturndebt_id: companyreturndebtId,
              company_id: this.$route.params.id,
              treasure_id: this.companyreturndebtObject.treasure_id,
              date: this.companyreturndebtObject.date,
              iqd_amount: this.companyreturndebtObject.iqd_amount,
              usd_amount: this.companyreturndebtObject.usd_amount,
              user_id: Vue.prototype.dashboardAuthId(),
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.readData();
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    deleteCompanyReturnDebt(companyreturndebtId) {
      CompaniesRequests.deleteReturnedDebt(
        {
          deleteReturnedDebt: true,
          companyreturndebt_id: companyreturndebtId,
          company_id: this.$route.params.id,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.readData();
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },
    readData() {
      CompaniesRequests.getOne(
        {
          getOneCompanies: true,
          company_id: this.$route.params.id,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
            setTimeout(() => {
              this.$router.push("/*");
            }, 1000);
          } else if (res.data.status === true) {
            this.error_msg = null;

            if ((this.$store.state.dashboardauth.role_id != 1 && this.$store.state.dashboardauth.role_id != 2)
              && res.data.data.user_id != this.$store.state.dashboardauth.id) {
              setTimeout(() => {
                this.$router.push("/*");
              }, 1000);
            }

            this.companyinfo = res.data.data;
          }
          this.cleanMessages();
        }
      ),
        CompaniesRequests.getAllReturnDebt(
          {
            getAllReturnDebt: true,
            company_id: this.$route.params.id,
          },
          (res) => {
            if (res.data.status === true) {
              this.error_msg = null;
              this.companyreturndebt = res.data.data;
            }
            this.cleanMessages();
          }
        );

      setTimeout(() => {
        if (!this.permissionExist("#view_companies")) {
          this.$router.push("/dashboard/home");
        }
      }, 500);
    },
  },
};
</script>
<style scoped>
@media print {
  .notprintable {
    display: none;
  }

  .printable {
    display: block;
  }
}
</style>