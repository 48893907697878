<template lang="">
  <div >

    <v-app-bar class="navbar" outlined elevation="4" dense app>
     
      <v-app-bar-nav-icon large @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn
      small
        class="ma-1 bg-main white--text"
        link
        to="/dashboard"
      >
        <v-icon>
          mdi-home
        </v-icon>
      </v-btn>


      <v-toolbar dense rounded flat class="mb-1" outlined>

<v-menu offset-y rounded v-model="lang_menu">
      <template v-slot:activator="{ on, attrslang }">
        <v-btn
        small
          class="ma-1 bg-main white--text"
          v-bind="attrslang"
          v-on="on"
        >
          {{langkeyword('LANGUAGE')}}
          <v-icon v-if="lang_menu">mdi-menu-up</v-icon>
          <v-icon v-else>mdi-menu-down</v-icon>
        </v-btn>
      </template>
<v-list style="text-align-last:center">
  <v-list-item v-for="(lang, index) in langs" :key="index" link>
    <v-list-item-title v-on:click="changeLang(lang.value)">{{ lang.title }}</v-list-item-title>
  </v-list-item>
</v-list>
</v-menu>

<v-switch class="ma-1" color="main" hide-details @click="darkmode()" v-model="switchBtn"></v-switch>

<v-btn small class="ma-1 bg-main white--text" @click="load()"><v-icon>mdi-refresh</v-icon></v-btn>

<v-spacer></v-spacer>


<v-menu offset-y>
  <template v-slot:activator="{ on, attrs }">
  <v-btn large class="mx-4" icon color="light" v-bind="attrs" v-on="on">
    <v-icon>mdi-account</v-icon>
  </v-btn>
</template>

  <v-list nav outlined class="mode" two-line>
    <center>
      <v-avatar class="mode" width="80" height="80">
        <v-img v-if="avatar!=null" style="height:100%;width:100%;" alt="profilePicture"
          :src="uploadPath(avatar)"></v-img>
        <v-img v-if="avatar===null" style="height:100%;width:100%;" alt="profilePicture"
          :src="uploadPath(logo)"></v-img>
      </v-avatar>
      <br>
      <v-btn link to="/dashboard/profile" class="mt-2 mode" outlined>{{ username }}</v-btn>
    </center>
    <v-list-item>
      <v-list-item-title>
        <v-dialog v-model="dialog" persistent max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text large width="100%">
            <span class="mx-4">{{langkeyword('LOGOUT')}}</span> 
<v-icon :style="directionLang().dir=='rtl' ? 'transform:rotate(180deg)' : '' ">mdi-logout-variant</v-icon>
           </v-btn>
          </template>
          <v-card class="logout">
            <v-card-title class="logout">
              {{langkeyword('WARNING')}}
            </v-card-title>
            <v-card-text>{{langkeyword('ARE_YOU_SURE_YOU_WANT_CLOSE_ACCOUNT')}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="dialog = false">
                {{langkeyword('NO')}}
              </v-btn>
              <v-btn class="bg-main white--text" text @click="logout()">
                {{langkeyword('YES')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list-item-title>
    </v-list-item>
  </v-list>
</v-menu>




</v-toolbar>
</v-app-bar>


<v-navigation-drawer v-model="drawer" app :right="langoptions('rtl')==true">
  <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title :class=" lang()=='en'? 'text-justify pa-2 ml-1' : 'text-justify pa-2 mr-1' ">
            <h3>  <v-icon color="light">mdi-view-dashboard</v-icon>
             <span :class="lang()=='en' ? 'ml-7' : 'mr-7'" style="font-size:18px;"> 
              {{ $store.state.dashboardauth.store_id!=null ? (lang()==='ku' ? $store.state.dashboardauth.stores_ku_name : null) || (lang()==='ar' ? $store.state.dashboardauth.stores_ar_name : null) || (lang()==='en' ? $store.state.dashboardauth.stores_en_name : null) : appName() }}
            </span> </h3>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> 
      </template>

  <v-divider class="my-3"></v-divider>

  <v-list nav rounded>
    <v-list-item-group v-model="selectedItem">

      <v-list-item class="ps-2" to="/dashboard/home" link>
        <v-list-item-icon>
          <v-icon>mdi mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="item-title">{{langkeyword('HOME')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>



      <!-- menu -->
      <v-list-group v-for="(menuitem, index) in menus" :key="index" :prepend-icon="menuitem.icon"
        :value="$route.fullPath.includes('/'+menuitem.tag+'/')===true ? true : false">

        <template v-slot:activator>
  <v-list-item-content>
    <v-list-item-title v-if="lang()==='ku'">{{menuitem.ku_name}}</v-list-item-title> 
    <v-list-item-title v-if="lang()==='ar'">{{menuitem.ar_name}}</v-list-item-title> 
    <v-list-item-title v-if="lang()==='en'">{{menuitem.en_name}}</v-list-item-title> 
  </v-list-item-content>
</template>


        <!-- page -->
        <v-list-item v-for="(pageitem, index) in pages.filter((p) =>
{ 
return (p.menu_id==menuitem.id && permissionExist('#view_'+p.tag))
})" :key="index" class="ps-4" link :to="'/'+pageitem.route">
          <v-list-item-icon>
            <v-icon>{{pageitem.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-if="lang()==='ku'">{{pageitem.ku_name}}</v-list-item-title>
            <v-list-item-title v-if="lang()==='ar'">{{pageitem.ar_name}}</v-list-item-title>
            <v-list-item-title v-if="lang()==='en'">{{pageitem.en_name}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- end page -->



      </v-list-group>
      <!-- end menu -->


    </v-list-item-group>

    <v-list-item class="my-5 pa-2 text-main">
      <v-list-item-subtitle class="text-center text-main" dir="ltr" style="font-size:1rem;">
        <span v-if="lang()==='en'">&copy;</span>
        {{ $store.state.dashboardauth.store_id!=null ? (lang()==='ku' ? $store.state.dashboardauth.stores_ku_name :
        null) || (lang()==='ar' ? $store.state.dashboardauth.stores_ar_name : null) || (lang()==='en' ?
        $store.state.dashboardauth.stores_en_name : null) : appName() }}
        <span v-if="lang()!='en'">&copy;</span>
      </v-list-item-subtitle>
    </v-list-item>



  </v-list>



</v-navigation-drawer>




</div>
</template>


<script>
import Vue from "vue";
import AppRequests from "../../requests/App";
import MyHelpers from "../../helpers";
import NavRequests from "../../requests/dashboard/Nav";
import MenuRequests from "../../requests/dashboard/system/Menus";
export default {
  data: function () {
    return {
      messages: 5,
      menu: false,
      menus: this.$store.state.menusbypermissions,
      hints: true,
      username: this.$store.state.dashboardauth.name,
      avatar: this.$store.state.dashboardauth.avatar,
      logo: this.$store.state.setting.logo,
      selectedItem: 0,
      drawer: null,
      setFont: [],
      dialog: false,
      lang_menu: false,
      langs: [
        { title: this.langkeyword("KURDISH"), value: "ku" },
        { title: this.langkeyword("ENGLISH"), value: "en" },
        { title: this.langkeyword("ARABIC"), value: "ar" },
      ],
      backcolor: "#615bff",
      textcolor: "#FFFFFF",
      switchBtn: false,
    };
  },
  methods: {
    load() {
      this.$store.commit("fetchData");
      MenuRequests.getByPermissions(
        {
          getByPermissions: true,
          permissions: this.$store.state.dashboardauth.roles_permissions,
        },
        (res) => {
          if (res.data.status === true) {
            this.$store.state.menusbypermissions = res.data.data;
          }
        }
      );
      if (
        localStorage.getItem("lang") == undefined ||
        localStorage.getItem("lang") == "undefined" ||
        localStorage.getItem("lang") == null ||
        localStorage.getItem("lang") == ""
      ) {
        AppRequests.firstFetch({ isLoadApp: true }, (res) => {
          if (res.data.status === true) {
            Vue.use(MyHelpers);
            localStorage.setItem("lang", res.data.fetchsetting.lang);
            this.$store.state.setting = res.data.fetchsetting;
            this.$store.state.actlang = res.data.fetchsetting.lang;

            MenuRequests.getByPermissions(
              {
                getByPermissions: true,
                permissions: this.$store.state.dashboardauth.roles_permissions,
              },
              (res) => {
                if (res.data.status === true) {
                  this.$store.state.menusbypermissions = res.data.data;
                }
              }
            );
          }
        });
      } else {
        this.$store.state.actlang = localStorage.getItem("lang");
        Vue.use(MyHelpers);
      }
      this.fetchDatas();
      this.$route.path != '/dashboard/home' ? this.$router.push("/dashboard") : ''
    },
    fetchDatas() {
      AppRequests.firstFetch({ isLoadApp: true }, (res) => {
        if (res.data.status === true) {
          this.$store.state.setting = res.data.fetchsetting;
          document.getElementById("appfavicon").href = this.uploadPath(
            this.$store.state.setting.favicon
          );
          //console.log(this.$store.state.setting)
        }
      });
    },
    changeLang(lang) {
      localStorage.removeItem("lang");
      localStorage.setItem("lang", lang);
      this.$store.state.actlang = lang;
      window.location.href = "";
    },
    authCheck() {
      NavRequests.checkAuth(
        {
          isAuthCheck: true,
          user_id: this.dashboardAuthId(),
          token: this.dashboardAuthToken(),
        },
        (res) => {
          if (res.data.status === false) {
            this.$router.push("/dashboard/auth/login");
          } else if (res.data.status === true) {
            this.$store.state.dashboardauth = res.data.data;
            this.menus = res.data.menus;
            this.avatar = this.$store.state.dashboardauth.avatar;
            this.username = this.$store.state.dashboardauth.name;
            this.logo = this.$store.state.setting.logo;
          }
        }
      );
    },

    getMenus() {
      MenuRequests.getByPermissions(
        {
          getByPermissions: true,
          permissions: this.$store.state.dashboardauth.roles_permissions,
        },
        (res) => {
          if (res.data.status === true) {
            this.$store.state.menusbypermissions = res.data.data;
            this.menus = res.data.data;
          }
        }
      );
    },

    darkmode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
        "dashboardTheme",
        this.$vuetify.theme.dark.toString()
      );
    },
    logout() {
      NavRequests.logOut(
        {
          isLogout: true,
          user_id: this.dashboardAuthId(),
        },
        (res) => {
          if (res.data.status === true) {
            localStorage.removeItem("dashboardauthId");
            localStorage.removeItem("dashboardauthToken");
            this.$router.push("/dashboard/auth/login");
          }
        }
      );
    },
  },
  mounted() {
    this.authCheck();
    setTimeout(() => {
      this.getMenus();
    }, 100);
    const AppLanguage = this.$store.state.actlang;
    if (AppLanguage == "ku" || AppLanguage == "ar") {
      this.$vuetify.rtl = true;
    }
    if (AppLanguage == "en") {
      this.$vuetify.rtl = false;
    }

    const theme = localStorage.getItem("dashboardTheme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
        this.switchBtn = true;
      } else {
        this.$vuetify.theme.dark = false;
        this.switchBtn = false;
      }
    }
  },
  computed: {
    pages() {
      return this.$store.getters.getpages;
    },
  },
};
</script>


<style lang="css">
@font-face {
  font-family: "nzar";
  src: local("nzar"), url(../../assets/fonts/nzar.ttf) format("truetype");
}

:root {
  --navbar-color: #615bff;
  --navbar-text: #ffffff;
  --bgGreyColor: #eeedf7;
  --grayColor: rgb(0, 0, 0, 54%);
  --bgdarkblue: #1d2125;
  --darknavbar: #1a1e22;
  --another: #1a1e22;
  --comp: #1a1e22;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #615bff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #615bff;
}

/* body  background */
.bg-main {
  background: #615bff !important;
}

.text-main {
  color: #615bff !important;
}

.theme--light .dashboard {
  background: #eeedf7 !important;
}

.theme--dark .dashboard {
  background: #1d2125 !important;
}


.theme--light .bg-mode {
  background: #ffffff !important;
}

.theme--dark .bg-mode {
  background: #1a1e22 !important;
}

.v-btn {
  letter-spacing: 0 !important;
  padding: 10px;
  font-weight: bolder;
}

.dashboard .account,
.dashboard.theme-text,
.dashboard.logout {
  font-family: "nzar" !important;
}

/* active tab color */
.theme--dark .dashboard .v-list-item-group .v-list-item--active {
  color: #abaaf2 !important;
}

/* navigation drawer */

.theme--dark .dashboard .v-navigation-drawer {
  background: var(--another) !important;
}

/* logout */
.theme--dark .logout {
  background: var(--darknavbar) !important;
}

* {
  font-family: "nzar";
}

/*  set color for list item text*/
.theme--light .dashboard .v-list .v-list-item__title {
  color: var(--another) !important;
}

/* when user hover of any list focused and set background  */
.theme--light .dashboard .v-list-item--active:hover::before,
.theme--light .dashboard .v-list-item--active::before {
  background: #615bff;
  color: #615bff;
}

.theme--dark .dashboard.v-list-item--active:hover::before,
.theme--dark .dashboard.v-list-item--active::before {
  background: #3a3f51;
}

/* card */
.theme--dark .dashboard .v-card {
  background: var(--another) !important;
}

.client-footer .dashboard .v-card {
  background: #eeedf7 !important;
}

.theme--light .dashboard .v-list .v-list-item--active .v-icon {
  color: #615bff !important;
}

.theme--light .dashboard .v-list .v-list-item--active .v-list-item__title {
  color: var(--another) !important;
}

.theme--dark.v-data-table {
  background: var(--another) !important;
}

/* data table hover row */
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #3a3f51 !important;
}

/* data table radius */
.dashboard .v-data-table .v-toolbar__content,
.v-toolbar__extension {
  border-radius: 15px !important;
}

.theme--dark .dashboard .v-data-table .v-sheet {
  border-radius: 0px !important;
  background: var(--another) !important;
}

.dashboard .v-data-table {
  padding: 15px !important;
}

/* navbar background  */

/* for light mode */

.theme--light .dashboard .navbar .v-toolbar.v-sheet {
  background: rgb(255, 255, 255) !important;
}

.theme--light .dashboard .navbar {
  background: rgb(255, 255, 255) !important;
}

/* for dark mode */
.theme--dark .dashboard .v-app-bar.v-toolbar.v-sheet,
.theme--dark .dashboard .v-toolbar__content,
.dashboard .v-toolbar__extension {
  background: var(--another) !important;
}

.theme--dark .dashboard .v-app-bar.client-navbar.v-sheet,
.theme--dark .dashboard .client-navbar .v-toolbar__content {
  background: #eeedf7 !important;
}

/* pagination */
.theme--dark .dashboard .v-pagination .v-pagination__item {
  background: var(--another) !important;
}

.theme--dark .dashboard .v-pagination .v-pagination__item--active {
  background: #0d47a1 !important;
}

.theme--dark .dashboard .v-pagination .v-pagination__navigation {
  background: var(--another) !important;
}

/* tabs */

.theme--dark .dashboard .v-tabs-items {
  background: var(--another) !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.theme--dark .dashboard .v-tabs>.v-tabs-bar {
  background: #3a3f51 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

/* account */
.dashboard .account {
  border-radius: 10px !important;
  font-size: 12px !important;
  color: white !important;
}

.dashboard .account::before {
  background: transparent !important;
}

.dashboard .account:hover {
  background: #484c5a !important;
  border-radius: 10px !important;
}

/* footer */
.theme--light .dashboard .v-footer {
  background: white !important;
  color: #3a3f51 !important;
}

.theme--dark .dashboard .v-footer {
  background: var(--another) !important;
  color: white !important;
}

/* v-sheet */
.theme--dark .dashboard .slider-group {
  background: var(--bgdarkblue) !important;
}

/* bottom navigation */
.theme--dark .dashboard .bottom-nav {
  background: var(--another) !important;
}

.theme--light .dashboard .bottom-nav {
  background: var(--navbar-color) !important;
}

/* skeleton loader background */

.theme--dark .dashboard .v-skeleton-loader .v-skeleton-loader__actions,
.theme--dark.v-skeleton-loader .v-skeleton-loader__article,
.theme--dark.v-skeleton-loader .v-skeleton-loader__card-heading,
.theme--dark.v-skeleton-loader .v-skeleton-loader__card-text,
.theme--dark.v-skeleton-loader .v-skeleton-loader__date-picker,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-text,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-two-line,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-three-line,
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line,
.theme--dark.v-skeleton-loader .v-skeleton-loader__table-heading,
.theme--dark.v-skeleton-loader .v-skeleton-loader__table-thead,
.theme--dark.v-skeleton-loader .v-skeleton-loader__table-tbody,
.theme--dark.v-skeleton-loader .v-skeleton-loader__table-tfoot {
  background: var(--another) !important;
}

.theme--light .mode {
  background: #ffffff !important;
}

.theme--dark .mode {
  background: #1a1e22 !important;
  border: 1px solid #37393d;
}


@media only screen and (max-width: 700px) {
  .navbar {
    zoom: 95% !important;
  }
}
</style>