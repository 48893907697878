<template>
    <div>
        <v-container class="mb-4">
            <v-row class="text-center">
                <v-col cols="12 text-start mx-auto notprintable" sm="11">
                    <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                    </v-btn>
                    <v-btn @click="refreshAll()" :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text info" x-large>
                        <v-icon> mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn onclick="window.print()" :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text primary" x-large>
                        <v-icon> mdi-printer</v-icon>
                    </v-btn>
                </v-col>

            </v-row>

            <v-row class="text-center mt-5 col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6 ma-auto">
                <v-text-field clearable filled class="nzar-font ma-auto col-11 notprintable" rounded outlined
                    append-icon="mdi-magnify" v-model="search" :label="langkeyword('SEARCH')">
                </v-text-field>
            </v-row>

            <v-row class="text-center">
                <v-col cols="12" class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mx-auto text-center pa-1">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        {{ langkeyword('NAME') }}
                                    </th>
                                    <th class="text-center">
                                        {{ langkeyword('BARCODE') }}
                                    </th>
                                    <th class="text-center">
                                        {{ langkeyword('QUANTITY') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(product, index) in products" :key="index">
                                    <td>
                                        <span v-if="lang() === 'ku'">{{ product.ku_name }}</span>
                                        <span v-if="lang() === 'ar'">{{ product.ar_name }}</span>
                                        <span v-if="lang() === 'en'">{{ product.en_name }}</span>
                                    </td>
                                    <td>{{ product.barcode }}</td>
                                    <td>
                                        <v-btn
                                            :class="product.qty == 0 ? 'red pa-2 white--text' : 'success pa-2 white--text'">
                                            {{ product.qty }}
                                            <v-chip v-if="product.unit_many != 1" class="dark--text pa-1 ma-2 d-none"
                                                small color="white">
                                                {{
                                                    parseFloat((product.qty / product.unit_one_from_many)).toFixed(1)
                                                }}
                                                <span v-if="lang() === 'ku'">{{ product.unitsmany_ku_name }}</span>
                                                <span v-if="lang() === 'ar'">{{ product.unitsmany_ar_name }}</span>
                                                <span v-if="lang() === 'en'">{{ product.unitsmany_en_name }}</span>
                                            </v-chip>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>



            </v-row>
            <v-row class="text-center printable">

            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
export default {
    data() {
        return {
            search: '',
            store_id: (Vue.prototype.state().getdashboardauth.store_id != '' && Vue.prototype.state().getdashboardauth.store_id != null) ? Vue.prototype.state().getdashboardauth.store_id : 1,
        };
    },
    mounted() {
        this.readData();
    },
    computed: {
        products() {
            return this.$store.getters.getproducts.filter(p =>
                p.ku_name.search(this.search) > -1 || (p.barcode).toString().search(this.search) > -1
            )
        },
        storeproducts() {
            return this.$store.getters.getstoreproducts.filter(sp => { return sp.store_id == this.store_id })
        },
    },
    methods: {
        readData() {
            this.products

            this.products.forEach(p => {
                this.storeproducts.filter(sp => { return (sp.product_id == p.id) }).forEach(sp => {
                    p.qty = parseFloat(sp.qty)
                    p.fastsaleqty = parseFloat(sp.qty)
                    p.fastbuyqty = parseFloat(sp.qty)
                    p.productslistingqty = parseFloat(sp.qty)
                })
            })

        },
        refreshAll() {
            this.search = ''
            this.readData()
        }
    },
};
</script>
<style scoped>
.container,
.container-fluid,
body {
    background: white;
}

@media print {
    .notprintable {
        display: none;
    }

    body,
    template {
        background: white;
    }
}
</style>