<template>
    <div>
        <navbar></navbar>
        <v-container class="mb-4">

            <v-row class="text-center printable">
                <v-col cols="12 text-start mx-auto" sm="12" md="12" lg="12" xl="12">
                    <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                        {{ langkeyword("BACK") }}
                    </v-btn>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{
                                langkeyword("PROFILE")
                                }}</h3>
                        </v-card-title>
                        <div class="pa-2" v-if="storeinfo.data != undefined && storeinfo.data != null">
                            <h4>{{ langkeyword("NAME") }} :
                                <span v-if="lang() === 'ku'">{{ storeinfo.data.ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ storeinfo.data.ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ storeinfo.data.en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("CITY") }} :
                                <span v-if="lang() === 'ku'">{{ storeinfo.data.cities_ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ storeinfo.data.cities_ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ storeinfo.data.cities_en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword('TREASURES') }} :
                                {{ storeinfo.treasures }}
                            </h4>
                            <h4>{{ langkeyword('PRODUCTS') }} :
                                {{ storeinfo.products }}
                            </h4>
                            <h4>{{ langkeyword('QUANTITY') }} :
                                {{ storeinfo.productquantities }}
                            </h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DINAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(storeinfo.totalDinarBuy) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(storeinfo.totalDinarBuyCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(storeinfo.totalDinarBuyDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dinar(storeinfo.totalDinarBuyFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DOLLAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(storeinfo.totalDollarBuy) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(storeinfo.totalDollarBuyCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(storeinfo.totalDollarBuyDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dollar(storeinfo.totalDollarBuyFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DINAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(storeinfo.totalDinarSale) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(storeinfo.totalDinarSaleCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(storeinfo.totalDinarSaleDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dinar(storeinfo.totalDinarSaleFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DOLLAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(storeinfo.totalDollarSale) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(storeinfo.totalDollarSaleCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(storeinfo.totalDollarSaleDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dollar(storeinfo.totalDollarSaleFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("PROFIT") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(storeinfo.totaldollarprofit) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(storeinfo.totaldollarprofitcash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(storeinfo.totaldollarprofitdebt) }}</h4>
                            <v-divider></v-divider>
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(storeinfo.totaldinarprofit) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(storeinfo.totaldinarprofitcash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(storeinfo.totaldinarprofitdebt) }}</h4>
                        </div>
                    </v-card>
                </v-col>
            </v-row>








            <v-row>
                <v-expansion-panels class="container mt-5" popout hover focusable>
                    <v-expansion-panel class="col-12 mode rounded-lg">
                        <v-expansion-panel-header expand-icon="mdi-shopping">
                            <h3>{{ langkeyword("PRODUCTS") }}</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-simple-table class="mt-3 col-12 ma-auto" dense>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            {{ langkeyword('PRODUCT') }}
                                        </th>
                                        <th class="text-center">
                                            {{ langkeyword('QUANTITY') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(storeproduct, index) in storeproducts" :key="index">
                                        <td class="text-center">
                                            <span v-if="lang() === 'ku'">{{ storeproduct.products_ku_name }}</span>
                                            <span v-if="lang() === 'ar'">{{ storeproduct.products_ar_name }}</span>
                                            <span v-if="lang() === 'en'">{{ storeproduct.products_en_name }}</span>
                                        </td>
                                        <td class="text-center">
                                            <span>{{ storeproduct.qty }}</span>
                                        </td>
                                        <td class="text-center">
                                            <v-icon v-if="permissionExist('#view_products')" small
                                                class="mr-2 info white--text px-2 py-2 rounded"
                                                @click="viewProduct(storeproduct.product_id)">
                                                mdi-information
                                            </v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>










            <v-row>
                <v-expansion-panels class="container mt-5" popout hover focusable>
                    <v-expansion-panel class="col-12 mode rounded-lg">
                        <v-expansion-panel-header expand-icon="mdi-treasure-chest">
                            <h3>{{ langkeyword("TREASURES") }}</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-simple-table class="mt-3 col-12 ma-auto" dense>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            {{ langkeyword('TREASURE') }}
                                        </th>
                                        <th class="text-center">
                                            {{ langkeyword('DINAR') }}
                                        </th>
                                        <th class="text-center">
                                            {{ langkeyword('DOLLAR') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(treasure, index) in treasures" :key="index">
                                        <td class="text-center">
                                            <span v-if="lang() === 'ku'">{{ treasure.ku_name }}</span>
                                            <span v-if="lang() === 'ar'">{{ treasure.ar_name }}</span>
                                            <span v-if="lang() === 'en'">{{ treasure.en_name }}</span>
                                        </td>
                                        <td class="text-center">
                                            <span>{{ dinar(treasure.iqd_amount) }}</span>
                                        </td>
                                        <td class="text-center">
                                            <span>{{ dollar(treasure.usd_amount) }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>







        </v-container>
        <br /><br /><br />
        <dashboardFooter />
    </div>
</template>

<script>
//import Vue from "vue"
import StoresRequests from "../../../requests/dashboard/subs/Stores"
import navbar from "../../../layouts/dashboard/nav.vue"
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue"
export default {
    components: {
        navbar,
        dashboardFooter,
    },
    data() {
        return {
            storeinfo: {},
            error_msg: null,
            success_msg: null,
        }
    },
    watch: {
        options: {
            handler() {
                this.readData()
            },
        },
    },
    mounted() {
        this.readData()
    },
    computed: {
        storeproducts() {
            return this.$store.getters.getstoreproducts.filter(sp => { return sp.store_id == this.$route.params.id })
        },
        treasures() {
            return this.$store.getters.gettreasures.filter(t => { return t.store_id == this.$route.params.id })
        },
    },
    methods: {
        cleanMessages() {
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        readData() {
            StoresRequests.getOne(
                {
                    getOneStores: true,
                    store_id: this.$route.params.id,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg)
                        setTimeout(() => {
                            this.$router.push("/*")
                        }, 1000)
                    } else if (res.data.status === true) {
                        this.error_msg = null
                        this.storeinfo = res.data
                    }
                    this.cleanMessages()
                }
            ),
                setTimeout(() => {
                    if (!this.permissionExist("#view_stores")) {
                        this.$router.push("/dashboard/home")
                    }
                }, 500)
        },
        viewProduct(id) {
            this.$router.push("/dashboard/product/products/" + id);
        }
    },
}
</script>
<style scoped>
@media print {
    .notprintable {
        display: none
    }

    .printable {
        display: block
    }
}
</style>