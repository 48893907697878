<template>
  <div>
    <v-snackbar v-if="error_msg != null" :timeout="-1" :value="true" color="red accent-2">
      {{ error_msg }}
    </v-snackbar>
    <v-snackbar v-if="success_msg != null" :timeout="-1" :value="true" color="green">
      {{ success_msg }}
    </v-snackbar>

    <navbar v-if="shownavandfooter === true"></navbar>
    <v-container fluid class="mt-4 mb-4">
      <v-row class="d-flex text-main justify-center">
        <v-col :cols="shownavandfooter === true ? '12' : '12'">
          <v-data-table :headers="headers" :items="products" :loading="loading" :search="search" :sort-desc="true"
            sort-by="id" :class="shownavandfooter === true
              ? 'elevation-1 nzar-font radius-15 mode'
              : 'nzar-font radius-15'
              " :footer-props="{
                showFirstLastPage: true,
                prevIcon: 'mdi-arrow-left',
                nextIcon: 'mdi-arrow-right',
                'items-per-page-text': langkeyword('PERPAGE'),
                'items-per-page-options': perPages(),
              }" :items-per-page="10">
            <template v-slot:top>
              <h2>
                <v-icon class="ma-1">mdi-shopping</v-icon>
                {{ langkeyword("PRODUCTS") }}
              </h2>
              <v-divider class="col-3 col-sm-3 col-md-2 col-lg-1 col-xl-1 mt-1"></v-divider>
              <v-toolbar flat class="radius-15">
                <v-dialog v-model="dialog" max-width="1500px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success" :style="permissionExist('#add_products')
                      ? 'color: white;float:right'
                      : 'display:none'
                      " class="radius-5 nzar-font" v-bind="attrs" v-on="on">
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("ADD") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode" outlined>
                    <v-card-title>
                      <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span>
                      <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span>
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>
                          <v-img v-if="
                            CURRENTFORM === 'edit' &&
                            productsObject.avatar != null &&
                            productsObject.avatar != ''
                          " width="100%" height="100%" class="mx-auto mt-1 mb-4 rounded-lg"
                            :src="uploadPath(productsObject.avatar)">
                          </v-img>

                          <v-file-input outlined v-if="CURRENTFORM != 'changeqty'" v-model="productsObject.avatar"
                            id="avatar" name="avatar" required accept="image/png, image/jpeg, image/bmp"
                            append-icon="mdi-camera" :label="langkeyword('IMAGE')">
                          </v-file-input>

                          <v-text-field outlined class="nzar-font" append-icon="mdi-format-title"
                            v-model="productsObject.ku_name" :label="langkeyword('NAME')">
                          </v-text-field>
                          <v-text-field outlined class="d-none nzar-font" append-icon="mdi-format-title"
                            v-model="productsObject.ar_name" :label="langkeyword('AR_NAME')">
                          </v-text-field>
                          <v-text-field outlined class="d-none nzar-font" append-icon="mdi-format-title"
                            v-model="productsObject.en_name" :label="langkeyword('EN_NAME')">
                          </v-text-field>

                          <v-text-field outlined class="nzar-font" append-icon="mdi-barcode"
                            v-model="productsObject.barcode" :label="langkeyword('BARCODE')">
                          </v-text-field>

                          <v-autocomplete v-if="isAdd() === true || isEdit() === true"
                            v-model="productsObject.productcategory_id" :items="productcategories"
                            :item-text="langoptions('value') + '_name'" item-value="id" clearable
                            :label="langkeyword('PRODUCTCATEGORY')" outlined></v-autocomplete>

                          <v-autocomplete v-if="
                            (isAdd() === true || isEdit() === true) &&
                            setting().hidebrandproduct == 'no'
                          " v-model="productsObject.brand_id" :items="brands"
                            :item-text="langoptions('value') + '_name'" item-value="id" clearable
                            :label="langkeyword('BRAND')" outlined></v-autocomplete>

                          <v-autocomplete v-if="
                            (isAdd() === true || isEdit() === true) &&
                            setting().hideunitproduct == 'no'
                          " v-model="productsObject.unit_one" :items="units"
                            :item-text="langoptions('value') + '_name'" item-value="id" clearable class=""
                            :label="langkeyword('UNITONE')" outlined></v-autocomplete>

                          <v-autocomplete v-if="
                            (isAdd() === true || isEdit() === true) &&
                            setting().hideunitproduct == 'no'
                          " v-model="productsObject.unit_many" :items="units"
                            :item-text="langoptions('value') + '_name'" item-value="id" clearable class=""
                            :label="langkeyword('UNITMANY')" outlined></v-autocomplete>

                          <v-text-field outlined v-if="setting().hideunitproduct == 'no'" type="number" min="0"
                            step="any" class="nzar-font" v-model="productsObject.unit_one_from_many"
                            :label="langkeyword('UNITONEFROMMANY')">
                          </v-text-field>

                          <v-text-field outlined v-if="setting().hidedinarproduct == 'no'" type="number" min="0"
                            step="any" class="nzar-font" v-model="productsObject.buy_price_iqd" :label="langkeyword('BUYPRICE') +
                              ' ــ ' +
                              langkeyword('IQD')
                              ">
                          </v-text-field>

                          <v-text-field outlined v-if="setting().hidedollarproduct == 'no'" type="number" min="0"
                            step="any" class="nzar-font" v-model="productsObject.buy_price_usd" :label="langkeyword('BUYPRICE') +
                              ' ــ ' +
                              langkeyword('USD')
                              ">
                          </v-text-field>

                          <v-text-field outlined v-if="setting().hidedinarproduct == 'no'" type="number" min="0"
                            step="any" class="nzar-font" v-model="productsObject.sale_price_iqd" :label="langkeyword('SALEPRICE') +
                              ' ــ ' +
                              langkeyword('IQD')
                              ">
                          </v-text-field>

                          <v-text-field outlined v-if="setting().hidedollarproduct == 'no'" type="number" min="0"
                            step="any" class="nzar-font" v-model="productsObject.sale_price_usd" :label="langkeyword('SALEPRICE') +
                              ' ــ ' +
                              langkeyword('USD')
                              ">
                          </v-text-field>

                          <v-autocomplete outlined v-if="isAdd() === true || isEdit() === true" append-icon="mdi-store"
                            v-model="productsObject.store_id" :items="stores"
                            :item-text="langoptions('value') + '_name'" item-value="id" :label="langkeyword('STORE')"
                            :class="isNormal() ? 'd-none' : ''"></v-autocomplete>

                          <v-text-field outlined type="number" min="0" step="any" class="nzar-font"
                            v-model="productsObject.qty" :label="langkeyword('QUANTITY')">
                          </v-text-field>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="bg-main white--text" v-if="isAdd() === true" dark @click="saveProducts">
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isEdit() === true" dark
                        @click="updateProducts(productsObject.id)">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="1000px">
                  <v-card outlined class="bg-mode">
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="red lighten-2" dark @click="deleteProducts(productsObject.id)">
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field outlined dense v-if="permissionExist('#search_products')" class="nzar-font"
                      v-model="search" append-icon="mdi-magnify" :label="langkeyword('SEARCH')"
                      hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-slot:[`item.avatar`]="{ item }">
              <v-img width="70px" height="70px" class="mx-auto bg-mode rounded-lg"
                :src="uploadPath(item.avatar)"></v-img>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-icon v-if="permissionExist('#edit_products')" small class="mr-2 orange white--text px-2 py-2 rounded"
                @click="editDialog(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon v-if="permissionExist('#delete_products')" small class="mr-2 red white--text px-2 py-2 rounded"
                @click="deleteDialog(item)">
                mdi-delete-circle
              </v-icon>
              <v-icon v-if="permissionExist('#view_products')" small
                class="mr-2 info ma-2 white--text px-2 py-2 rounded" @click="viewProduct(item)">
                mdi-information
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <dashboardFooter v-if="shownavandfooter === true" />
  </div>
</template>

<script>
import Vue from "vue"
import ProductsRequests from "../../../requests/dashboard/product/Products"
import navbar from "../../../layouts/dashboard/nav.vue"
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue"
export default {
  props: {
    shownavandfooter: {
      default: true,
      type: Boolean,
    },
  },
  components: {
    navbar,
    dashboardFooter,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    snackbar: true,
    products: [],
    search: "",
    loading:
      Vue.prototype.getters().getproducts == null ||
        Vue.prototype.getters().getproducts == "" ||
        Vue.prototype.getters().getproducts == undefined
        ? true
        : false,
    error_msg: null,
    success_msg: null,
    headers: [
      { text: "#", value: "id", align: "center" },
      {
        text: Vue.prototype.langkeyword("NAME"),
        value: Vue.prototype.langoptions("value") + "_name",
        align: "center",
      },
      {
        text:
          Vue.prototype.langkeyword("BUYPRICE") +
          " ــ " +
          Vue.prototype.langkeyword("USD"),
        value: "buy_price_usd",
        align:
          Vue.prototype.setting().hidedollarproduct == "no"
            ? "center"
            : " d-none",
      },
      {
        text:
          Vue.prototype.langkeyword("BUYPRICE") +
          " ــ " +
          Vue.prototype.langkeyword("IQD"),
        value: "buy_price_iqd",
        align:
          Vue.prototype.setting().hidedinarproduct == "no"
            ? "center"
            : " d-none",
      },
      {
        text:
          Vue.prototype.langkeyword("SALEPRICE") +
          " ــ " +
          Vue.prototype.langkeyword("USD"),
        value: "sale_price_usd",
        align:
          Vue.prototype.setting().hidedollarproduct == "no"
            ? "center"
            : " d-none",
      },
      {
        text:
          Vue.prototype.langkeyword("SALEPRICE") +
          " ــ " +
          Vue.prototype.langkeyword("IQD"),
        value: "sale_price_iqd",
        align:
          Vue.prototype.setting().hidedinarproduct == "no"
            ? "center"
            : " d-none",
      },
      {
        text: Vue.prototype.langkeyword("IMAGE"),
        value: "avatar",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("BARCODE"),
        value: "barcode",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("PRODUCTCATEGORY"),
        value:
          "productcategories_" + Vue.prototype.langoptions("value") + "_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("BRAND"),
        value: "brands_" + Vue.prototype.langoptions("value") + "_name",
        align:
          Vue.prototype.setting().hidebrandproduct == "no"
            ? "center"
            : " d-none",
      },
      {
        text: Vue.prototype.langkeyword("ACTIONS"),
        value: "edit",
        sortable: false,
        align: "center",
      },
    ],
    CURRENTFORM: "add",
    productsObject: {
      user_id: Vue.prototype.dashboardAuthId(),
      avatar: null,
      ku_name: "",
      ar_name: "",
      en_name: "",
      barcode: null,
      buy_price_usd: 0,
      buy_price_iqd: 0,
      sale_price_usd: 0,
      sale_price_iqd: 0,
      unit_one: 1,
      unit_many: 1,
      productcategory_id: "",
      brand_id: "",
      unit_one_from_many: 1,
      store_id: Vue.prototype.userStore(),
      qty: 0,
    },
  }),
  watch: {
    options: {
      handler() {
        this.readData()
      },
    },
  },
  mounted() {
    this.readData()
  },
  computed: {
    units() {
      return this.$store.getters.getunits
    },
    productcategories() {
      return this.$store.getters.getproductcategories
    },
    brands() {
      return this.$store.getters.getbrands
    },
    stores() {
      return this.$store.getters.getstores
    },
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true
      } else {
        return false
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true
      } else {
        return false
      }
    },
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null
        this.error_msg = null
      }, 3000)
    },
    validate(params, callback) {
      this.error_msg = null
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED")
        }
      })
      if (this.error_msg == null) {
        return callback()
      }
      this.cleanMessages()
    },
    editDialog(item) {
      this.CURRENTFORM = "edit"
      let itemQty = Vue.prototype.state().getstoreproducts.filter((sp) => {
        return (
          sp.store_id == Vue.prototype.userStore() && sp.product_id == item.id
        )
      })[0].qty
      this.productsObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          avatar: null,
          ku_name: "",
          ar_name: "",
          en_name: "",
          barcode: null,
          buy_price_usd: 0,
          buy_price_iqd: 0,
          sale_price_usd: 0,
          sale_price_iqd: 0,
          unit_one: 1,
          unit_many: 1,
          productcategory_id: "",
          brand_id: "",
          unit_one_from_many: 1,
          store_id: Vue.prototype.userStore(),
          qty: itemQty,
        },
        item
      )
      item.qty = itemQty
      this.productsObject.qty = itemQty
      this.dialog = true
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock"
      this.productsObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          avatar: null,
          ku_name: "",
          ar_name: "",
          en_name: "",
          barcode: null,
          buy_price_usd: 0,
          buy_price_iqd: 0,
          sale_price_usd: 0,
          sale_price_iqd: 0,
          unit_one: 1,
          unit_many: 1,
          productcategory_id: "",
          brand_id: "",
          unit_one_from_many: 1,
          store_id: Vue.prototype.userStore(),
          qty: 0,
        },
        item
      )
      this.dialog = true
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete"
      this.productsObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          avatar: null,
          ku_name: "",
          ar_name: "",
          en_name: "",
          barcode: null,
          buy_price_usd: 0,
          buy_price_iqd: 0,
          sale_price_usd: 0,
          sale_price_iqd: 0,
          unit_one: 1,
          unit_many: 1,
          productcategory_id: "",
          brand_id: "",
          unit_one_from_many: 1,
          store_id: Vue.prototype.userStore(),
          qty: 0,
        },
        item
      )
      this.dialogDelete = true
    },
    closeMainDialog() {
      this.dialog = false
      this.$nextTick(() => {
        this.productsObject = Object.assign(
          {
            user_id: Vue.prototype.dashboardAuthId(),
            avatar: null,
            ku_name: "",
            ar_name: "",
            en_name: "",
            barcode: null,
            buy_price_usd: 0,
            buy_price_iqd: 0,
            sale_price_usd: 0,
            sale_price_iqd: 0,
            unit_one: 1,
            unit_many: 1,
            productcategory_id: "",
            brand_id: "",
            unit_one_from_many: 1,
            store_id: Vue.prototype.userStore(),
            qty: 0,
          },
          this.defaultItem
        )
        this.CURRENTFORM = "add"
      })
    },
    closeDeleteDialog() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.productsObject = Object.assign(
          {
            user_id: Vue.prototype.dashboardAuthId(),
            avatar: null,
            ku_name: "",
            ar_name: "",
            en_name: "",
            barcode: null,
            buy_price_usd: 0,
            buy_price_iqd: 0,
            sale_price_usd: 0,
            sale_price_iqd: 0,
            unit_one: 1,
            unit_many: 1,
            productcategory_id: "",
            brand_id: "",
            unit_one_from_many: 1,
            store_id: Vue.prototype.userStore(),
            qty: 0,
          },
          this.defaultItem
        )
        this.CURRENTFORM = "add"
      })
    },
    readData() {
      this.products = this.$store.getters.getproducts
      setTimeout(() => {
        if (!this.permissionExist("#view_products")) {
          this.$router.push("/dashboard/home")
        }
      }, 500)
    },
    saveProducts() {
      this.validate(
        [
          this.productsObject.ku_name,
          this.productsObject.unit_one,
          this.productsObject.unit_many,
          this.productsObject.productcategory_id,
          this.productsObject.store_id,
        ],
        () => {
          const avatarname = document.getElementById("avatar").files[0]
          const addParams = new FormData()
          addParams.append("addProducts", true)
          addParams.append("ku_name", this.productsObject.ku_name)
          addParams.append("ar_name", this.productsObject.ku_name)
          addParams.append("en_name", this.productsObject.ku_name)
          addParams.append("barcode", this.productsObject.barcode)
          addParams.append("qty", this.productsObject.qty)
          addParams.append("buy_price_usd", this.productsObject.buy_price_usd)
          addParams.append("buy_price_iqd", this.productsObject.buy_price_iqd)
          addParams.append(
            "sale_price_usd",
            this.productsObject.sale_price_usd
          )
          addParams.append(
            "sale_price_iqd",
            this.productsObject.sale_price_iqd
          )
          addParams.append("unit_one", this.productsObject.unit_one)
          addParams.append("unit_many", this.productsObject.unit_many)
          addParams.append(
            "unit_one_from_many",
            this.productsObject.unit_one_from_many
          )
          addParams.append(
            "productcategory_id",
            this.productsObject.productcategory_id
          )
          addParams.append("brand_id", this.productsObject.brand_id)
          addParams.append("avatar", avatarname)
          addParams.append("user_id", Vue.prototype.dashboardAuthId())
          addParams.append("store_id", this.productsObject.store_id)
          const config = {
            headers: {
              "content-type": `multipart/form-data boundary=${addParams._boundary}`,
            },
          }
          ProductsRequests.add(addParams, config, (res) => {
            if (res.data.status === false) {
              this.error_msg = this.langkeyword(res.data.msg)
            } else if (res.data.status === true) {
              this.error_msg = null
              this.success_msg = this.langkeyword(res.data.msg)
              this.$store.state.products = res.data.data
              this.products = res.data.data
              this.$store.state.storeproducts = res.data.storeproducts
              this.closeMainDialog()
            }
            this.cleanMessages()
          })
        }
      )
    },
    updateProducts(productId) {
      this.validate(
        [
          this.productsObject.ku_name,
          this.productsObject.unit_one,
          this.productsObject.unit_many,
          this.productsObject.productcategory_id,
          this.productsObject.store_id,
        ],
        () => {
          const avatarname = document.getElementById("avatar").files[0]
          const editParams = new FormData()
          editParams.append("editProducts", true)
          editParams.append("product_id", productId)
          editParams.append("ku_name", this.productsObject.ku_name)
          editParams.append("ar_name", this.productsObject.ku_name)
          editParams.append("en_name", this.productsObject.ku_name)
          editParams.append("barcode", this.productsObject.barcode)
          editParams.append("qty", this.productsObject.qty)
          editParams.append("buy_price_usd", this.productsObject.buy_price_usd)
          editParams.append("buy_price_iqd", this.productsObject.buy_price_iqd)
          editParams.append(
            "sale_price_usd",
            this.productsObject.sale_price_usd
          )
          editParams.append(
            "sale_price_iqd",
            this.productsObject.sale_price_iqd
          )
          editParams.append("unit_one", this.productsObject.unit_one)
          editParams.append("unit_many", this.productsObject.unit_many)
          editParams.append(
            "unit_one_from_many",
            this.productsObject.unit_one_from_many
          )
          editParams.append(
            "productcategory_id",
            this.productsObject.productcategory_id
          )
          editParams.append("brand_id", this.productsObject.brand_id)
          editParams.append("avatar", avatarname)
          editParams.append("user_id", Vue.prototype.dashboardAuthId())
          editParams.append("store_id", this.productsObject.store_id)
          const config = {
            headers: {
              "content-type": `multipart/form-data boundary=${editParams._boundary}`,
            },
          }
          ProductsRequests.edit(editParams, config, (res) => {
            if (res.data.status === false) {
              this.error_msg = this.langkeyword(res.data.msg)
            } else if (res.data.status === true) {
              this.error_msg = null
              this.success_msg = this.langkeyword(res.data.msg)
              this.$store.state.products = res.data.data
              this.products = res.data.data
              this.$store.state.storeproducts = res.data.storeproducts
              this.closeMainDialog()
            }
            this.cleanMessages()
          })
        }
      )
    },
    deleteProducts(productId) {
      ProductsRequests.delete(
        { deleteProducts: true, product_id: productId },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg)
          } else if (res.data.status === true) {
            this.error_msg = null
            this.success_msg = this.langkeyword(res.data.msg)
            this.$store.state.products = res.data.data
            this.products = res.data.data
            this.closeDeleteDialog()
          }
          this.cleanMessages()
        }
      )
    },
    viewProduct(item) {
      this.$router.push("/dashboard/product/products/" + item.id)
    },
  },
}
</script>
